/*
 *   File : manage-question.js
 *   Author : https://evoqins.com
 *   Description : Manage question list
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Custom components
import { Breadcrumb } from "../../Components/Others";
import { CustomSelect, CustomTextInput1, PercentageInput, RadioGroup } from "../../Components/FormElements";
import { Icon } from "../../Components/Icon";
import { PrimaryButton, SecondaryButton } from "../../Components/Buttons";

import APIService from "../../Services/api-service";

const QUESTION_TYPE_1 = [
    {
        answer_id: null,
        answer: "",
        score: "",
        default: false,
        error: ['', '']
    },
    {
        answer_id: null,
        answer: "",
        score: "",
        default: false,
        error: ['', '']
    }
]

const QUESTION_TYPE_2 = [
    {
        range_start: "",
        range_end: "",
        score: "",
        error: ["", "", ""]
    }
]

const ManageQuestion = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const questionRef = useRef(false);
    const weightageRef = useRef(false);
    const answersRef = useRef(false);
    const rangeRef = useRef(false);
    const postFixRef = useRef(false);

    const [question, setQuestion] = useState("");
    const [questionError, setQuestionError] = useState("");
    const [questionTypes, setQuestionTypes] = useState([]);
    const [questionType, setQuestionType] = useState(null);
    const [weightage, setWeightage] = useState("");
    const [weightageError, setWeightageError] = useState("");
    const [categoryTypes, setCategoryTypes] = useState([]);
    const [selectedCategoryType, setSelectedCategoryType] = useState(null);
    const [selectedCategoryTypeError, setSelectedCategoryTypeError] = useState([]);


    // question type 1
    const [answersOptions, setAnswersOptions] = useState(JSON.parse(JSON.stringify(QUESTION_TYPE_1)));

    // question type 2
    const [startingValue, setStartingValue] = useState("");
    const [startingValueError, setStartingValueError] = useState("");
    const [endingValue, setEndingValue] = useState("");
    const [endingValueError, setEndingValueError] = useState("");
    const [defaultValue, setDefaultValue] = useState("");
    const [defaultValueError, setDefaultValueError] = useState("");
    const [stepValue, setStepValue] = useState("");
    const [postFixEnabled, setPostFixEnabled] = useState(false);
    const [singularPostfix, setSingularPostfix] = useState("");
    const [singularPostfixError, setSingularPostfixError] = useState("");
    const [pluralPostfix, setPluralPostfix] = useState("");
    const [pluralPostfixError, setPluralPostfixError] = useState("");

    // question type 3

    const [actualRangeSlabs, setActualRangeSlabs] = useState(JSON.parse(JSON.stringify(QUESTION_TYPE_2)));

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        _getQuestionTypes();
        _getCategoryTypes();
    }, []);

    useEffect(() => {
        if (location.state !== null) {
            const category_type = categoryTypes.find((item) => location.state.category.category_id === item.value)
            setSelectedCategoryType(category_type);
            setQuestion(location.state.question.question);
            setWeightage(location.state.question.weight);
            const type = questionTypes.find((item) => location.state.question.question_type === item.id);
            if (type) {
                setQuestionType(type.id);
            }
            if (location.state.question.question_type === 1) {
                let answer_options = []
                location.state.question.answers.map((item) => {
                    return (
                        answer_options.push({
                            answer_id: item.id,
                            answer: item.answer,
                            score: item.score,
                            error: ["", ""]
                        })
                    )
                })
                setAnswersOptions(answer_options)
            } else if (location.state.question.question_type === 2) {

                setStartingValue(location.state.question.config.min_max.min);
                setEndingValue(location.state.question.config.min_max.max);
                setDefaultValue(location.state.question.config.min_max.default);
                setStepValue(location.state.question.config.min_max.step);
                setPostFixEnabled(location.state.question.config.postfix_enabled);
                setSingularPostfix(location.state.question.config.singular_postfix);
                setPluralPostfix(location.state.question.config.plural_postfix);
            } else if (location.state.question.question_type === 3) {
                let actual_range_slabs = []
                location.state.question.answers.map((item) => {
                    return (
                        actual_range_slabs.push({
                            range_start: item.answer,
                            score: item.score,
                            error: ["", "", ""]
                        })
                    )
                })
                setActualRangeSlabs(actual_range_slabs);
            }

        }
    }, [location.state, categoryTypes, questionTypes]);

    function _handleCategoryType(select_value) {
        setSelectedCategoryType(select_value)
    }

    function _handleNavigate() {
        navigate("/manage-risk");
    }

    function _handleQuestion(input_value) {
        setQuestion(input_value);
        setQuestionError('');
    }

    function _handleQuestionType(selected) {
        setQuestionType(selected);
        setQuestionError("");
        setWeightageError("");
        setStartingValueError("");
        setEndingValueError("");
        setStartingValue("");
        setEndingValue("");
        setDefaultValue("");
        setStepValue("");
        setSingularPostfixError("");
        setPluralPostfixError("");
        setAnswersOptions(JSON.parse(JSON.stringify(QUESTION_TYPE_1)));
        setActualRangeSlabs(JSON.parse(JSON.stringify(QUESTION_TYPE_2)))
    }

    function _handleWeightage(input_value) {
        if (input_value <= 100) {
            setWeightage(input_value);
        }
        setWeightageError('');
    }

    // Add options question type 1
    function _handleAddMCQ() {
        let answer_options = [...answersOptions]
        answer_options.push({
            answer_id: null,
            answer: "",
            score: "",
            default: false,
            error: ['', '']

        });
        setAnswersOptions(answer_options)
    }

    // Delete options question type 1
    function _handleDeleteMCQ(index) {
        let answer_options = [...answersOptions]
        answer_options.splice(index, 1);
        setAnswersOptions(answer_options)
    }

    // Handle values question type 1
    function _handleMultipleChoices(input_value, index, key, error_index) {
        let answer_options = [...answersOptions]
        if (key === 'score') {
            if (input_value <= 10) {
                answer_options[index][key] = input_value;
            }
        } else {
            answer_options[index][key] = input_value;
        }
        answer_options[index]['error'][error_index] = ""
        setAnswersOptions(answer_options);
    }

    // Handle actual figure values question type 3
    function _handleActualRangeSlab(input_value, index, key, error_index) {
        let actual_range_slabs = [...actualRangeSlabs]
        if (key === 'score') {
            if (input_value <= 10) {
                actual_range_slabs[index][key] = input_value;
            }
        } else {
            actual_range_slabs[index][key] = input_value;
        }
        actual_range_slabs[index]['error'][error_index] = ""
        setActualRangeSlabs(actual_range_slabs);
    }

    // Add figures question type 3
    function _handleAddFigures() {
        let actual_range_slab = [...actualRangeSlabs]
        actual_range_slab.push({
            range_start: "",
            range_end: "",
            score: "",
            error: ["", "", ""]
        });
        setActualRangeSlabs(actual_range_slab)
    }

    // Add figures question type 3
    function _handleDeleteActualSlab(index) {
        let delete_figures = [...actualRangeSlabs]
        delete_figures.splice(index, 1);
        setActualRangeSlabs(delete_figures)
    }

    // Starting value question type 2
    function _handleStartingValue(input_value) {
        setStartingValue(input_value);
        setStartingValueError("");
    }

    // Ending value question type 2
    function _handleEndingValue(input_value) {
        setEndingValue(input_value);
        setEndingValueError("");
    }
    // default value question type 2
    function _handleDefaultValue(input_value) {
        setDefaultValue(input_value);
        setDefaultValueError("");
    }

    // question type 2
    const _handlePostFix = () => {
        setPostFixEnabled(!postFixEnabled);
    }

    // question type 2
    const __handleSingularPostfix = (value) => {
        setSingularPostfix(value);
        setSingularPostfixError("");
    }

    // question type 2
    const __handlePluralPostfix = (value) => {
        setPluralPostfix(value);
        setPluralPostfixError("");
    }



    function _submitForm() {
        let valid = true;

        if (questionType === 1) {
            const answer_options = [...answersOptions]
            for (let i = 0; i < answer_options.length; i++) {
                if (answer_options[i]['answer'] === "") {
                    answersRef.current.scrollIntoView({ block: 'start' });
                    answer_options[i].error[0] = 'Option is required'
                    valid = false
                }
                if (answer_options[i]['score'] === "" || answer_options[i]['score'] <= 0) {
                    answersRef.current.scrollIntoView({ block: 'start' });
                    answer_options[i].error[1] = ('Score is required');
                    valid = false
                }
            }


            setAnswersOptions(answer_options);

        } else if (questionType === 2) {
            if (startingValue === "") {
                rangeRef.current.scrollIntoView({ block: 'start' });
                setStartingValueError("Starting value is required");
                valid = false
            }
            if (endingValue === "") {
                rangeRef.current.scrollIntoView({ block: 'start' });
                setEndingValueError("Ending value is required");
                valid = false
            }
            if (defaultValue === "") {
                rangeRef.current.scrollIntoView({ block: 'start' });
                setDefaultValueError("Default value is required");
                valid = false
            }


            if (postFixEnabled) {
                if (singularPostfix === "") {
                    postFixRef.current.scrollIntoView({ block: 'start' });
                    setSingularPostfixError("Singular postfix value is required");
                    valid = false
                }
                if (pluralPostfix === "") {
                    postFixRef.current.scrollIntoView({ block: 'start' });
                    setPluralPostfixError("Plural postfix value is required");
                    valid = false
                }
            }

        } else if (questionType === 3) {

            const actual_range_slabs = [...actualRangeSlabs]
            for (let i = 0; i < actual_range_slabs.length; i++) {
                if (actual_range_slabs[i]['range_start'] === "") {
                    answersRef.current.scrollIntoView({ block: 'start' });
                    actual_range_slabs[i].error[0] = ('Answer is required');
                    valid = false
                }
                if (actual_range_slabs[i]['score'] === "") {
                    answersRef.current.scrollIntoView({ block: 'start' });
                    actual_range_slabs[i].error[2] = ('Score is required');
                    valid = false
                }
            }

            setActualRangeSlabs(actual_range_slabs);
        }
        if (selectedCategoryType === null) {
            weightageRef.current.scrollIntoView({ block: 'start' });
            setSelectedCategoryTypeError("Category type is required");
            valid = false
        }
        if (weightage === "") {
            weightageRef.current.scrollIntoView({ block: 'start' });
            setWeightageError("Weightage is required");
            valid = false
        }
        if (question === "") {
            questionRef.current.scrollIntoView({ block: 'start' });
            setQuestionError("Question is required");
            valid = false
        }
        if (valid === true) {
            setLoader(true);
            _saveQuestion();
        }
    }

    // API - Save risk questionnaire 
    const _saveQuestion = () => {
        const url = "/quiz/upsert-question";
        let answer_options = [];
        answersOptions.map((item) => {

            return (
                answer_options.push({
                    answer_id: item.answer_id ? item.answer_id : undefined,
                    answer: item.answer,
                    score: parseInt(item.score),
                })
            )
        })
        const request = {
            question_id: location.state === null ? null : location.state.question.question_id,
            question: question,
            question_type: questionType,
            weight: weightage,
            order: 1,
            category_id: selectedCategoryType.value,
            config: questionType !== 2 ? null : {
                min_max: {
                    min: startingValue,
                    max: endingValue,
                    default: defaultValue,
                    step: stepValue
                },
                postfix_enabled: postFixEnabled,
                singular_postfix: singularPostfix,
                plural_postfix: pluralPostfix,
            },

            answers: questionType === 1 ? answer_options : []
        };

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                navigate("/manage-risk");
                toast.success("Risk question updated", {
                    type: "success",
                });
            } else {

                toast.error(response.message, {
                    type: 'error'
                });
            }
            setLoader(false);
        })
    }

    // API - get risk question types
    const _getQuestionTypes = () => {
        const url = "/quiz/get-questiontype";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                let question_types = []
                response.data.map((item) => {
                    return (
                        question_types.push({
                            label: item.name,
                            id: item.id
                        })
                    )
                });
                setQuestionTypes(question_types);
                setQuestionType(question_types[0].id);
            }
            else {
                setQuestionTypes([]);
            }
        });
    }

    // API - get category tyes
    const _getCategoryTypes = () => {
        const url = "/quiz/get-category";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                let category_types = []
                response.data.map((item) => {
                    return (
                        category_types.push({
                            label: item.category,
                            value: item.category_id
                        })
                    )
                });
                setCategoryTypes(category_types);
            }
            else {
                setQuestionTypes([]);
            }
        });
    }

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <div className="padding-110px-bottom">
                    <Breadcrumb handleNavigate={_handleNavigate}
                        mainTitle="Manage risk profiling"
                        subTitle="Manage question" />
                </div>
            </div>
            <div className="e-body-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="bg-white border-radius-32px padding-40px-tb padding-32px-lr">
                            <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-dark-charcoal mb-4">
                                Add Question
                            </h4>


                            <div className="row">
                                <div className="col-lg-8 col-md-8 mb-2" ref={questionRef}>
                                    <CustomTextInput1 label="Question"
                                        postfix="*"
                                        id="question"
                                        error={questionError}
                                        value={question}
                                        placeHolder="Question"
                                        handleChange={_handleQuestion} />
                                </div>
                                <div className="col-lg-5 col-md-5 mb-2" ref={weightageRef}>
                                    <PercentageInput label="Question weightage"
                                        postfix="*"
                                        error={weightageError}
                                        value={weightage}
                                        placeHolder="Weightage"
                                        handleChange={_handleWeightage} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-5 col-md-6 mb-2">
                                    <p className={`e-montserrat-medium e-font-14 e-line-height-22 margin-6px-bottom color-dark-charcoal`}>
                                        Category type
                                    </p>
                                    <CustomSelect options={categoryTypes}
                                        value={selectedCategoryType}
                                        type={3}
                                        error={selectedCategoryTypeError}
                                        placeholder="Select Category type"
                                        selectChange={_handleCategoryType}
                                    />
                                </div>

                                <div className="col-12">
                                    <label className="e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-2 letter-spacing-025px">Question type<span className='color-deep-carmine-pink'>
                                        *
                                    </span></label>
                                    <RadioGroup
                                        className={`${location.state !== null && "disabled"}`}
                                        id={questionType}
                                        data={questionTypes}
                                        radioHandler={_handleQuestionType} />
                                </div>
                            </div>
                            {
                                questionType === 1 ?
                                    <Fragment>
                                        <h4 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-dark-charcoal letter-spacing-025px margin-32px-top">
                                            Add options and score value*
                                        </h4>
                                        <div ref={answersRef}>
                                            {
                                                answersOptions.map((item, index) => {
                                                    return (
                                                        <div className="row mb-2 d-flex align-items-center row-gap-24px">
                                                            <div className="col-lg-4 col-sm-5">
                                                                <CustomTextInput1 label={`Option ${index + 1}`}
                                                                    value={item.answer}
                                                                    placeHolder={`Option ${index + 1}`}
                                                                    type="text"
                                                                    postfix="*"
                                                                    error={item.error[0]}
                                                                    handleChange={(input_value) => _handleMultipleChoices(input_value, index, 'answer', 0)} />
                                                            </div>
                                                            <div className="col-lg-4 col-sm-6">
                                                                <CustomTextInput1 label={`Score`}
                                                                    value={item.score}
                                                                    placeHolder={`Score`}
                                                                    type="number"
                                                                    error={item.error[1]}
                                                                    postfix="*"
                                                                    min={0}
                                                                    handleChange={(input_value) => _handleMultipleChoices(input_value, index, 'score', 1)} />
                                                            </div>
                                                            <div className={`col-1 w-max-content d-flex align-items-center gap-8px mt-3`}
                                                                onClick={() => {
                                                                    // Update the default values based on the clicked item
                                                                    let updated = answersOptions.map((answer, i) => ({
                                                                        ...answer,
                                                                        default: i === index, // Set to true only for the clicked item, false for others
                                                                    }));
                                                                    setAnswersOptions(updated);
                                                                    // _handleConfig('postfix_enabled', !config.postfix_enabled);
                                                                }}>
                                                                {/* <span>
                                                                    <Icon icon={item.default ? 'checked' : 'unchecked'}
                                                                        size={24}
                                                                        className="cursor-pointer"
                                                                    />
                                                                </span>
                                                                <span className="color-dark-charcoal e-montserrat-medium e-font-14 e-line-height-24 letter-spacing-007em">
                                                                    Default value
                                                                </span> */}
                                                            </div>
                                                            <div className="col-lg-1 col-1 mt-4">
                                                                {answersOptions.length > 2 && <Icon icon="delete"
                                                                    size={24}
                                                                    className="cursor-pointer e-delete-hover"
                                                                    // color={index === onDeleteHover && "#f6172d"}
                                                                    // onMouseEnter={() => _handleDeleteHover(index)}
                                                                    // onMouseLeave={() => _handleDeleteHover(null)}
                                                                    onClick={() => _handleDeleteMCQ(index)} />}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="color-rebecca-purple e-montserrat-semi-bold e-font-16 e-line-height-14 gap-8px margin-32px-top mb-3 d-flex align-items-center w-max-content cursor-pointer"
                                            onClick={_handleAddMCQ}>
                                            <Icon icon="add"
                                                size={24} />Add new option</div>
                                    </Fragment>
                                    :
                                    questionType === 2 ?
                                        <Fragment>

                                            <h4 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-dark-charcoal letter-spacing-025px margin-32px-top mb-4">
                                                Add range values*
                                            </h4>
                                            <div className="row row-gap-24px " ref={rangeRef}>
                                                <div className="col-md-3 col-sm-6">
                                                    <CustomTextInput1 label={`Starting value`}
                                                        value={startingValue}
                                                        placeHolder={`Starting value`}
                                                        type="number"
                                                        postfix="*"
                                                        error={startingValueError}
                                                        handleChange={_handleStartingValue} />
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <CustomTextInput1 label={`Ending value`}
                                                        value={endingValue}
                                                        placeHolder={`Ending value`}
                                                        type="number"
                                                        postfix="*"
                                                        error={endingValueError}
                                                        handleChange={_handleEndingValue} />
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <CustomTextInput1 label={`Default value`}
                                                        value={defaultValue}
                                                        placeHolder={`Default value`}
                                                        type="number"
                                                        postfix="*"
                                                        error={defaultValueError}
                                                        handleChange={_handleDefaultValue} />
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center gap-8px mt-4 w-max-content"
                                                onClick={_handlePostFix}>
                                                <span>
                                                    <Icon icon={postFixEnabled ? 'checked' : 'unchecked'}
                                                        size={24}
                                                        className="cursor-pointer"
                                                    />
                                                </span>
                                                <span className="color-black e-montserrat-medium e-font-14 line-height-24px cursor-pointer">Postfix enabled</span>
                                            </div>
                                            {
                                                postFixEnabled &&
                                                <div className="row row-gap-24px mt-4" ref={postFixRef}>
                                                    <div className="col-md-3 col-sm-6">
                                                        <CustomTextInput1 label={`Singular value`}
                                                            value={singularPostfix}
                                                            placeHolder={`Singular value`}
                                                            postfix="*"
                                                            error={singularPostfixError}
                                                            handleChange={__handleSingularPostfix} />
                                                    </div>
                                                    <div className="col-md-3 col-sm-6">
                                                        <CustomTextInput1 label={`Plural value`}
                                                            value={pluralPostfix}
                                                            placeHolder={`Plural value`}
                                                            postfix="*"
                                                            error={pluralPostfixError}
                                                            handleChange={__handlePluralPostfix} />
                                                    </div>
                                                </div>
                                            }

                                            {/* <h4 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-dark-charcoal letter-spacing-025px my-4">
                                                Add options and score*
                                            </h4>
                                            <div ref={answersRef}
                                                className="d-flex flex-column gap-24px">
                                                {
                                                    rangeSlabs.map((item, index) => {
                                                        return (
                                                            <div className="row row-gap-24px d-flex align-items-center">
                                                                <div className="col-lg-2 col-md-6">
                                                                    <CustomTextInput1 label={`From`}
                                                                        value={item.range_start}
                                                                        placeHolder={`From`}
                                                                        type="number"
                                                                        postfix="*"
                                                                        error={item.error[0]}
                                                                        handleChange={(input_value) => _handleRange(input_value, index, 'range_start', 0)} />
                                                                </div>
                                                                <div className="col-lg-2 col-md-6">
                                                                    <CustomTextInput1 label={`To`}
                                                                        value={item.range_end}
                                                                        placeHolder={`To`}
                                                                        type="number"
                                                                        error={item.error[1]}
                                                                        postfix="*"
                                                                        handleChange={(input_value) => _handleRange(input_value, index, 'range_end', 1)} />
                                                                </div>
                                                                <div className="col-lg-4 col-md-11 col-11">
                                                                    <CustomTextInput1 label={`Score`}
                                                                        value={item.score}
                                                                        placeHolder={`Score`}
                                                                        type="number"
                                                                        error={item.error[2]}
                                                                        min={0}
                                                                        postfix="*"
                                                                        handleChange={(input_value) => _handleRange(input_value, index, 'score', 2)} />
                                                                </div>
                                                                <div className="col-lg-1 col-1 pt-4">
                                                                    {rangeSlabs.length > 1 && <Icon icon="delete"
                                                                        size={24}
                                                                        className="cursor-pointer e-delete-hover"
                                                                        // color={index === onDeleteHover && "#f6172d"}
                                                                        // onMouseEnter={() => _handleDeleteHover(index)}
                                                                        // onMouseLeave={() => _handleDeleteHover(null)}
                                                                        onClick={() => _handleDeleteRange(index)} />}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div> */}
                                            {/* <div className="color-rebecca-purple e-montserrat-semi-bold e-font-16 e-line-height-14 gap-8px margin-32px-top mb-3 d-flex align-items-center w-max-content cursor-pointer"
                                                onClick={_handleAddRange}>
                                                <Icon icon="add"
                                                    size={24} />Add new option</div> */}
                                        </Fragment>

                                        :
                                        <Fragment>
                                            <h4 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-dark-charcoal letter-spacing-025px margin-32px-top mb-4">
                                                Add option and score value*
                                            </h4>
                                            <div ref={answersRef}>
                                                {
                                                    actualRangeSlabs.map((item, index) => {
                                                        return (
                                                            <div className="row mb-2 d-flex align-items-center">
                                                                <div className="col-lg-4 col-sm-6 pb-lg-0 pb-4">
                                                                    <CustomTextInput1 label={`Answer`}
                                                                        value={item.range_start}
                                                                        placeHolder={`Answer`}
                                                                        type="text"
                                                                        postfix="*"
                                                                        error={item.error[0]}
                                                                        handleChange={(input_value) => _handleActualRangeSlab(input_value, index, 'range_start', 0)} />
                                                                </div>
                                                                <div className="col-lg-4 col-sm-11">
                                                                    <CustomTextInput1 label={`Score`}
                                                                        value={item.score}
                                                                        placeHolder={`Score`}
                                                                        type="number"
                                                                        error={item.error[2]}
                                                                        postfix="*"
                                                                        min={0}
                                                                        handleChange={(input_value) => _handleActualRangeSlab(input_value, index, 'score', 2)} />
                                                                </div>
                                                                <div className="col-lg-1 col-1 pt-4">
                                                                    {actualRangeSlabs.length > 1 && <Icon icon="delete"
                                                                        size={24}
                                                                        className="cursor-pointer e-delete-hover"
                                                                        // color={index === onDeleteHover && "#f6172d"}
                                                                        // onMouseEnter={() => _handleDeleteHover(index)}
                                                                        // onMouseLeave={() => _handleDeleteHover(null)}
                                                                        onClick={() => _handleDeleteActualSlab(index)} />}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="color-rebecca-purple e-montserrat-semi-bold e-font-16 e-line-height-14 gap-8px margin-32px-top mb-3 d-flex align-items-center w-max-content cursor-pointer"
                                                onClick={_handleAddFigures}>
                                                <Icon icon="add"
                                                    size={24} />Add new option</div>
                                        </Fragment>


                            }


                            <div className="row padding-32px-top">
                                <div className="col-lg-8">
                                    <div className="d-flex justify-content-end gap-8px">
                                        <SecondaryButton label="Cancel"
                                            className="padding-14px-tb padding-50px-lr"
                                            handleClick={() => navigate("/manage-risk")} />
                                        <PrimaryButton label={location.state !== null ? "Update" : "Add"}
                                            className="padding-14px-tb padding-50px-lr"
                                            disabled={loader}
                                            loader={loader}
                                            handleClick={_submitForm} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ManageQuestion;
