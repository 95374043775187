
/*
 *   File : user-detail.js
 *   Author URI : https://evoqins.com
 *   Description : User detail summary
 *   Integrations : null
 *   Version : 1
 */

// Packages
import React, { memo } from "react";
import { Badge } from "../Badge";
import Config from "../../Helper/config";

// Custom components


const UserDetail = React.memo((props) => {
    return (
        <div className="row d-flex align-items-center">
            <div className="col-lg-2 col-md-12 col-12">
                <div className="d-flex align-items-center flex-column justify-content-start">
                    <div className="e-bg-lavender-mist border-radius-100 min-w-120px min-h-120px d-flex justify-content-center color-rebecca-purple align-items-center e-montserrat-semi-bold e-font-24 e-line-height-32">
                        {props.data.overview.name.at("0")}
                    </div>
                    <div className="d-block text-center">

                        <p className="mt-2 e-montserrat-semi-bold e-font-20 e-line-height-26 color-dark-charcoal mb-0 word-break">
                            {props.data.overview.name}
                        </p>

                    </div>
                </div>
            </div>

            <div className="col-lg-10 col-md-12 col-12 mt-lg-0 mt-3">
                <div className="e-bg-pale-lilac p-3 d-flex flex-wrap align-items-end row-gap-16px">

                    <div className="mb-xl-0 mb-4 padding-64px-right">
                        {
                            <div className="col-12 pb-2">
                                <Badge status={props.data.overview.kyc_complaint ? "KYC Verified" : "KYC Pending"}
                                    color={props.data.overview.kyc_complaint === true ? Config.BADGE_COLORS.green :
                                        Config.BADGE_COLORS.red}
                                />
                            </div>
                        }
                        <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-24 mb-xxl-3 mb-xl-1">
                            AUM
                        </h6>
                        <p className="color-dark-charcoal pt-xl-0 pt-0 e-font-14 e-line-height-24 e-montserrat-semi-bold mb-0">
                            ₹{props.data.investment_summary.aum}
                        </p>
                    </div>
                    <div className="mb-xl-0 mb-4 padding-64px-right">
                        <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-22 mb-xxl-3 mb-xl-1">
                            Current value
                        </h6>
                        <p className="color-dark-charcoal pt-xl-0 pt-0 e-font-14 e-line-height-24 e-montserrat-semi-bold mb-0">
                            ₹{props.data.investment_summary.current_value}
                        </p>
                    </div>

                    <div className="mb-xl-0 mb-4">
                        <h6 className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-22 mb-xxl-3 mb-xl-1">
                            Returns
                        </h6>
                        <p className={`pt-xl-0 pt-0 e-font-14 e-line-height-24 e-montserrat-semi-bold mb-0 
                            ${props.data.investment_summary.returns < 0 ? "color-red" : props.data.investment_summary.returns > 0 ? "color-emerald-green" : "color-dark-charcoal"}`}>
                            ₹{props.data.investment_summary.returns}
                            <span className={props.data.investment_summary.returns_percentage < 0 ? "color-red" : props.data.investment_summary.returns_percentage > 0 ? "color-emerald-green" : "color-dark-charcoal"}> ({props.data.investment_summary.returns_percentage})</span>
                        </p>
                    </div>

                </div>
            </div>

        </div >
    )
})

export default memo(UserDetail);