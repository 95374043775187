/*
 *   File : App.js
 *   Author : https://evoqins.com
 *   Description :
 *   Integrations : js-cookie,react-toastify
 *   Version : 1.0.0
*/

import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Toast } from './Components/Message';


// Custom components
import { Footer } from './Components/Footer';
import { CustomHeader, LeftNavigationWidget } from './Components/Navigation';
import { Dashboard } from './Containers/Dashboard';
import { ForgotPassword, Login } from './Containers/Auth';
import { CustomerDetail, CustomerListing } from './Containers/Customer';
import { OrderDetail, OrdersListing } from './Containers/Orders';
import { AllNotifications, NotificationListing } from './Containers/Notifications';
import { Banners } from './Containers/Banner';

import { _getNotificationCount } from './Helper/api';
import { FundListing } from './Containers/Funds';
import { ManageQuestion, RiskProfileList } from './Containers/RiskProfiler';
import { UserSettings } from './Containers/Categories';
import { ReturnsList } from './Containers/Returns';

function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}, [pathname]);

	return null;
}


function App() {
	const SIDEBAR_TOGGLE = useSelector(state => state.Reducer.toggle);
	const IS_LOGGED_IN = useSelector(state => state.Reducer.IS_LOGGED_IN);
	// eslint-disable-next-line
	const [adminType, setAdminType] = useState(1);

	//eslint-disable-next-line
	const [browserZoom, setBrowserZoom] = useState(100);

	useEffect(() => {
		if (IS_LOGGED_IN === true) {
			_getNotificationCount()
		}
	}, [IS_LOGGED_IN])


	const CustomerRouter = () => {
		return (
			<Outlet />
		)
	}

	const OrderRouter = () => {
		return (
			<Outlet />
		)
	}

	const NotificationRouter = () => {
		return (
			<Outlet />
		)
	}

	const RiskProfileRouter = () => {
		return (
			<Outlet />
		)
	}
	return (
		<Router>
			<ScrollToTop />
			{
				IS_LOGGED_IN === true ?
					<Fragment>
						<LeftNavigationWidget adminType={adminType} />
						<div className={`${browserZoom === 110 ? 'e-app-zoom-level-9' : browserZoom === 125 ? 'e-app-zoom-level-8' : browserZoom >= 150 ? 'e-app-zoom-level-7' : null}`}>
							<div className={`position-absolute d-flex flex-column h-100 
               					 ${SIDEBAR_TOGGLE === true ? "margin-268px-left" : "margin-92px-left"} transition`}
								style={{ width: SIDEBAR_TOGGLE === true ? "calc(100% - 268px)" : "calc(100% - 92px)" }}>
								<CustomHeader />
								{

									<Routes>
										{/* dashboard */}
										<Route path="/" element={<Dashboard />}></Route>

										{/* customers */}
										<Route path="/customer" element={<CustomerRouter />}>
											<Route index element={<CustomerListing />} />
											<Route path="detail" element={<CustomerDetail />} />
										</Route>

										{/* push notification */}
										<Route path="/push-notification" element={<NotificationRouter />}>
											<Route index element={<NotificationListing />} />
										</Route>

										<Route path="/banners" element={<Banners />}></Route>

										<Route path="/returns" element={<ReturnsList />}></Route>

										<Route path="/manage-mf" element={<FundListing />}></Route>

										<Route path="/app-settings" element={<UserSettings />}></Route>

										<Route path="/manage-risk" element={<RiskProfileRouter />}>
											<Route index element={<RiskProfileList />} />
											<Route path="manage-question" element={<ManageQuestion />} />
										</Route>


										<Route path="/orders" element={<OrderRouter />}>
											<Route index element={<OrdersListing />} />
											<Route path="detail" element={<OrderDetail />} />
										</Route>

										<Route path="/all-notifications" element={<AllNotifications />}></Route>



									</Routes>

								}


								{/* Footer */}
								<div className='mt-auto'>
									<Footer />
								</div>
							</div>
						</div>
					</Fragment>
					:
					<Fragment>
						<Routes>
							{/* dashboard */}
							<Route path="/" element={<Login />}></Route>
							<Route path="/forgot-password" element={<ForgotPassword />}></Route>
							<Route path="*" element={<Login />} />
						</Routes>

						{/* Footer */}
						<div className='mt-auto'>
							<Footer />
						</div>
					</Fragment>
			}
			<Toast />

		</Router >
	);
}

export default App;
