/*
 *   File : custom-select.js
 *   Author : https://evoqins.com
 *   Description : Custom select component
 *   Integrations : react-select
 *   Version : 1.0.0
*/

// packages
import React, { Fragment, useEffect, useState } from 'react';
import Select, { components } from "react-select";

// Custom styles
import Colors from '../../Styles/color.module.scss';

// Custom components
import { Icon } from '../Icon';


// type - 1 :: filter select
// type- 2 :: dashboard dropdown
//  type - 3 :: Input select

const CustomSelect = (props) => {

    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    // handle select value 
    function _onSelectChange(e) {
        props.selectChange(e)
    }

    const CUSTOM_STYLES = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            paddingTop: "4px !important",
            transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
        }),
        control: (styles, state) => ({
            ...styles,
            borderColor: Colors.pale_gray,
            background: Colors.white,
            boxShadow: 'none',
            borderRadius: "0.5rem",
            cursor: "pointer",
            alignItems: "center",
            "&:hover": {
                boxShadow: 'none',
            }
        }),

        indicatorSeparator: (styles, state) => ({
            ...styles,
            display: "none"
        }),
        placeholder: (styles, state) => ({
            ...styles,
            fontFamily: "Montserrat-Regular",
            fontSize: "14px",
            lineHeight: "18px",
            color: Colors.dark_charcoal
        }),
        singleValue: (styles, state) => ({
            ...styles,
            fontFamily: "Montserrat-Regular",
            fontSize: "14px",
            lineHeight: "18px",
            color: Colors.dark_charcoal
        }),
        menu: (styles, state) => ({
            ...styles,
            borderRadius: "16px",
            overflow: "hidden",
        }),
        menuList: (styles, state) => ({
            ...styles,
            position: 'relative', // Ensure relative positioning for the scrollbar to appear correctly
            '&::-webkit-scrollbar': {
                width: '8px',
                height: '0px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: Colors.scrollbar_color,
            },
        }),
        option: (styles, state) => ({
            ...styles,
            fontFamily: "Montserrat-Regular",
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "0.007em",
            color: state.isSelected ? Colors.white : Colors.dark_charcoal,
            backgroundColor: state.isSelected ? Colors.rebecca_purple : "",
            cursor: "pointer",
            "&:active": {
                backgroundColor: Colors.white,
            },
            "&:hover": {
                backgroundColor: state.isSelected ? Colors.rebecca_purple : Colors.lavender_gray,
                color: state.isSelected ? Colors.white : Colors.dark_charcoal,
            },
        })

    }

    const CUSTOM_STYLES_TYPE_3 = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            paddingTop: "4px !important",
            transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
        }),
        control: (styles, state) => ({
            ...styles,
            borderColor: state.selectProps.menuIsOpen === true ? Colors.rebecca_purple : Colors.light_gray,
            background: Colors.white,
            boxShadow: 'none',
            borderRadius: "0.5rem",
            cursor: "pointer",
            alignItems: "center",
            minHeight: "48px",
            "&:hover": {
                boxShadow: 'none',
            }
        }),

        indicatorSeparator: (styles, state) => ({
            ...styles,
            display: "none"
        }),
        placeholder: (styles, state) => ({
            ...styles,
            fontFamily: "Montserrat-regular",
            fontSize: "14px",
            lineHeight: "18px",
            color: Colors.dark_charcoal
        }),
        singleValue: (styles, state) => ({
            ...styles,
            fontFamily: "Montserrat-medium",
            fontSize: "16px",
            lineHeight: "18px",
            color: Colors.dark_charcoal
        }),
        valueContainer: (styles, state) => ({
            ...styles,
            padding: "9px 8px"
        }),
        menuList: (styles, state) => ({
            ...styles,
            position: 'relative', // Ensure relative positioning for the scrollbar to appear correctly,
            maxHeight: "200px",
            '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: Colors.scrollbar_color,
            },
        }),
        menu: (styles, state) => ({
            ...styles,
            zIndex: "2",
            borderRadius: "8px",
            overflow: "hidden",
        }),
        option: (styles, state) => ({
            ...styles,
            fontFamily: "Montserrat-medium",
            fontSize: "14px",
            lineHeight: "18px",
            letterSpacing: "0.007em",
            color: state.isSelected ? Colors.white : Colors.dark_charcoal,
            backgroundColor: state.isSelected ? Colors.rebecca_purple : "",
            cursor: "pointer",
            padding: "8px 16px",
            "&:active": {
                backgroundColor: Colors.white,
            },
            "&:hover": {
                backgroundColor: state.isSelected ? Colors.rebecca_purple : Colors.lavender_gray,
                color: state.isSelected ? Colors.white : Colors.dark_charcoal,
            },
        })

    }

    const CUSTOM_STYLES_DROPDOWN = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            padding: "0px",
            transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
        }),
        control: (styles, state) => ({
            ...styles,
            borderColor: 'transparent',
            background: Colors.lavender_gray,
            boxShadow: '0px 0px 2px 0px #00000033',
            borderRadius: "1.125rem",
            padding: "8px",
            cursor: "pointer",
            alignItems: "center",
            "&:hover": {
                boxShadow: '0px 0px 2px 0px #00000033',
            }
        }),
        valueContainer: (styles, state) => ({
            ...styles,
            width: "65px",
            padding: "0px"
        }),
        indicatorSeparator: (styles, state) => ({
            ...styles,
            display: "none"
        }),
        singleValue: (styles, state) => ({
            ...styles,
            fontFamily: "Montserrat-Medium",
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.007em",
            color: Colors.rebecca_purple
        }),
        menu: (styles, state) => ({
            ...styles,
            width: "140px",
            borderRadius: "16px",
            overflow: "hidden",
        }),
        option: (styles, state) => ({
            ...styles,
            fontFamily: "Montserrat-Medium",
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.007em",
            color: state.isSelected ? Colors.white : Colors.dark_charcoal,
            backgroundColor: state.isSelected ? Colors.rebecca_purple : "",
            cursor: "pointer",
            "&:active": {
                backgroundColor: Colors.white,
            },
            "&:hover": {
                backgroundColor: state.isSelected ? Colors.rebecca_purple : Colors.lavender_gray,
                color: state.isSelected ? Colors.white : Colors.dark_charcoal,
            },
        })

    }

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <Icon icon="arrow-down"
                    size={16} />
            </components.DropdownIndicator>
        );
    }
    return (
        <Fragment>
            {
                props.type === 3 ?
                    <div className={`${props.className} position-relative`}>

                        <p className={`${props.label_class} e-montserrat-medium e-font-14 e-line-height-18 margin-6px-bottom color-dark-charcoal`}>{props.label}
                            <span className='color-deep-carmine-pink'>{props.postFix === true && "*"}</span>
                        </p>
                        <Select value={value}
                            styles={CUSTOM_STYLES_TYPE_3}
                            isSearchable={false}
                            options={props.options}
                            id={props.id}
                            placeholder={props.placeholder}
                            menuPlacement={props.menuPlacement}
                            className="e-select-box"
                            // menuIsOpen={true}
                            isDisabled={props.isDisabled}
                            components={{
                                DropdownIndicator,
                                IndicatorSeparator: null,
                            }}
                            onChange={(value) => {
                                _onSelectChange(value)
                            }}
                        />
                        {
                            <div className=' h-10px d-flex align-items-center margin-6px-top '>
                                <p className='color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 mb-0 '>
                                    {props.error}
                                </p>
                            </div>
                        }
                    </div>
                    :
                    props.type === 2 ?
                        <Select isSearchable={props.isSearchable}
                            isClearable={props.isClearable}
                            className={`e-select-box`}
                            isDisabled={props.isDisabled}
                            options={props.options}
                            value={props.value ? props.value : ""}
                            isMulti={props.selectType}
                            placeholder={props.placeholder}
                            defaultValue={props.defaultValue}
                            components={{
                                DropdownIndicator,
                                IndicatorSeparator: null,
                            }}
                            // menuIsOpen={true}
                            styles={CUSTOM_STYLES_DROPDOWN}
                            onChange={(e) => { _onSelectChange(e) }}

                            {...props} />
                        :
                        <Select isSearchable={props.isSearchable}
                            isClearable={props.isClearable}
                            className={`e-select-box`}
                            isDisabled={props.isDisabled}
                            options={props.options}
                            value={props.value ? props.value : ""}
                            isMulti={props.selectType}
                            placeholder={props.placeholder}
                            defaultValue={props.defaultValue}
                            components={{
                                DropdownIndicator,
                                IndicatorSeparator: null,
                            }}
                            // menuIsOpen={true}
                            styles={CUSTOM_STYLES}
                            onChange={(e) => { _onSelectChange(e) }}

                            {...props} />}
        </Fragment>
    )
}

CustomSelect.defaultProps = {
    isSearchable: false
}

export default CustomSelect;