/*
 *   File : listing.js
 *   Author : https://evoqins.com
 *   Description : Order list
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

// Custom components
import { DataTableContainer } from "../../Components/DataTable";
import { CustomSelect, SearchInput } from "../../Components/FormElements";
import { Icon } from "../../Components/Icon";
import { Badge } from "../../Components/Badge";
import { CustomTitle } from "../../Components/Title";
import { PrimaryButton, DownloadButton, SecondaryButton, LinkButton } from "../../Components/Buttons";
import { CustomCalendar, EmptyScreen } from "../../Components/Others";

// Custom styles
import Colors from '../../Styles/color.module.scss';
// import { CustomCalendar } from "../../Components/Others";

// Services
import { _forceNavigate, _formatDate } from "../../Helper/helper";
import APIService from "../../Services/api-service";
import { _getOrderFilterData } from "../../Helper/api";
import Config from "../../Helper/config";


// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "24px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "24px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },
            '&:hover': {
                backgroundColor: Colors.lavender_mist
            }

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            cursor: "pointer",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const PAYMENT_METHODS = [
    {
        label: "All",
        value: null
    },
    {
        label: "NETBANKING",
        value: 1
    },
    {
        label: "UPI",
        value: 2
    }
]

const ORDER_TYPES = [
    {
        label: "All",
        value: null
    },
    {
        label: "Lumpsum",
        value: 1
    },
    {
        label: "SIP",
        value: 2
    },
    {
        label: "Withdrawal",
        value: 3
    }
]


const GOAL_TYPE = [
    {
        label: "Goal level",
        value: true
    },
    {
        label: "Fund level",
        value: false
    },
]


const OrdersListing = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const disabledDays = { after: new Date() };

    const [search, setSearch] = useState("");
    const [filterEnabled, setFilterEnabled] = useState(false);
    // const [selectedIds, setSelectedIds] = useState([]);
    const [paymentStatusOptions, setPaymentStatusOptions] = useState([]);
    const [orderStatusOptions, setOrderStatusOptions] = useState([]);

    const [selectedOrderType, setSelectedOrderType] = useState(ORDER_TYPES[0]);
    const [selectedPaymentType, setSelectedPaymentType] = useState(PAYMENT_METHODS[0]);
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(null);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);
    const [selectedMilestone, setSelectedMilestone] = useState(GOAL_TYPE[0]);
    const [fromDate, setFromDate] = useState("DD-MM-YYYY");
    const [endDate, setEndDate] = useState("DD-MM-YYYY");

    const [selectedOrder, setSelectedOrder] = useState(ORDER_TYPES[0]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PAYMENT_METHODS[0]);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [milestone, setMilestone] = useState(GOAL_TYPE[0]);
    const [from, setFrom] = useState("DD-MM-YYYY");
    const [end, setEnd] = useState("DD-MM-YYYY");
    const [searchValue, setSearchValue] = useState("");

    const [showTooltip, setShowTooltip] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState(null);
    const [tooltipContent, setTooltipContent] = useState("");
    const [filter, setFilter] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [tableLoader, setTableLoader] = useState(true);
    const [filterLoader, setFilterLoader] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);

    const [selectAll, setSelectAll] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState(new Set());
    const [rowSelectFalse, setRowSelectFalse] = useState(false);
    const [downloadLoader, setDownloadLoader] = useState(false);


    _forceNavigate(() => {
        //eslint-disable-next-line
        if (location.pathname == "/orders") {
            navigate(-2)
        }
    });

    useEffect(() => {
        _getFilterData();
    }, []);

    useEffect(() => {
        if (filterEnabled === true) {
            setSelectedPaymentStatus(selectedPayment);
            setSelectedOrderType(selectedOrder);
            setSelectedOrderStatus(selectedStatus);
            setSelectedPaymentType(selectedPaymentMethod);
            setSelectedMilestone(milestone)
            setFromDate(from)
            setEndDate(end);
            setSearch(searchValue)
        }
        // eslint-disable-next-line
    }, [filterEnabled])

    useEffect(() => {
        if (filterLoader === false) {
            if (location.state !== null) {
                if (location.state.selectedPaymentStatus.value !== null || location.state.selectedOrderStatus.value !== null || location.state.selectedPaymentType.value !== null ||
                    location.state.selectedMilestone.value !== null ||
                    location.state.selectedOrderType.value !== null || (location.state.fromDate !== "DD-MM-YYYY" && location.state.endDate !== "DD-MM-YYYY")) {
                    // setFilterEnabled(true);
                }
                _getOrderList(location.state.search, location.state.selectedPaymentStatus, location.state.selectedOrderType, location.state.selectedOrderStatus, location.state.selectedPaymentType, location.state.selectedMilestone,
                    location.state.endDate, location.state.fromDate, location.state.page)
            }
        }
    }, [location, filterLoader]);



    useEffect(() => {
        if (filterLoader === false) {
            if (location.state === null) {
                _getOrderList(search, paymentStatusOptions[0], ORDER_TYPES[0], orderStatusOptions[0], PAYMENT_METHODS[0], GOAL_TYPE[0], endDate, fromDate, pageNumber);
            }
        }
        // eslint-disable-next-line
    }, [filterLoader])

    useEffect(() => {
        if (filter === true) {
            _validate();
        }
        // eslint-disable-next-line
    }, [filter]);

    // for deselecting header checkbox in the table
    useEffect(() => {
        if (rowSelectFalse === true) {
            setSelectAll(false);
        }
    }, [rowSelectFalse]);

    useEffect(() => {
        if (selectAll === true) {
            const order_id_set = new Set(orderList.map((item) => item.order_id));
            setSelectedOrders((prevSet) => new Set([...prevSet, ...order_id_set]));
            setRowSelectFalse(false);
        }
        else {
            if (rowSelectFalse === false) {
                setSelectedOrders(new Set());
            }
        }
        //eslint-disable-next-line
    }, [selectAll, orderList])


    function _handleRowCheckBox(row, event) {
        // console.log("Row", row);
        event.stopPropagation();
        // console.log("before", selectedOrders)
        if (selectedOrders.has(row.order_id)) {
            setSelectedOrders((prevOrderIds) => {
                const new_order_ids = new Set(prevOrderIds);
                new_order_ids.delete(row.order_id);
                return new_order_ids;
            });
        }
        else {
            setSelectedOrders((prevOrderIds) => new Set([...prevOrderIds, row.order_id]));
        }
        setRowSelectFalse(true);
    }

    function _handleSelectAll(event) {
        event.stopPropagation();
        setSelectAll(!selectAll)
    }


    function _handleTooltip(event, status, reason) {
        setShowTooltip(status);
        if (status) {
            const boundingRect = event.target.getBoundingClientRect();
            const tooltipX = boundingRect.left + (boundingRect.width / 2);
            const tooltipY = boundingRect.top + boundingRect.height + window.scrollY;
            setTooltipPosition({ x: tooltipX, y: tooltipY });
            setTooltipContent(reason)
        }
    }

    function _handleNavigate(row) {
        navigate("/orders", {
            state: _filterData(row)
        });
        setTimeout(() => {
            navigate("detail", {
                state: _filterData(row)
            });
        }, 0);
    }

    function _handleSearch(search_value) {
        setSearch(search_value);
        if (search_value === "") {
            _getOrderList("", selectedPayment, selectedOrder, selectedStatus, selectedPaymentMethod, milestone, end, from, 1);
        }
    }

    function _handleOrderType(select_value) {
        setSelectedOrderType(select_value)
    }

    function _handleOrderStatus(select_value) {
        setSelectedOrderStatus(select_value)
    }

    function _handlePaymentStatus(select_value) {
        setSelectedPaymentStatus(select_value)
    }

    function _handlePaymentType(select_value) {
        setSelectedPaymentType(select_value)
    }

    function _handleInvestmentType(select_value) {
        setSelectedMilestone(select_value)
    }

    function _handleToggleFilter() {
        setFilterEnabled(!filterEnabled)
    }

    function _handleDate(date_range) {
        const start_date = _formatDate(date_range.from)
        const end_date = _formatDate(date_range.to);

        setFromDate(start_date);
        setEndDate(end_date);
    }

    function _filterData(row) {
        let filter_data = ""
        filter_data = {
            id: row.order_id,
            page: pageNumber,
            selectedPaymentStatus: selectedPayment,
            selectedOrderStatus: selectedStatus,
            selectedOrderType: selectedOrder,
            selectedPaymentType: selectedPaymentMethod,
            selectedMilestone: milestone,
            fromDate: from,
            endDate: end,
            search: searchValue
        }
        return filter_data
    }

    function _validate() {
        let valid = true;
        let error = "";

        if (valid === true) {
            setFilterEnabled(false);
            _getOrderList(search, selectedPayment, selectedOrder, selectedStatus, selectedPaymentMethod, milestone, end, from, pageNumber);
        }
        else {
            toast.dismiss();
            toast.error(error, {
                type: "error",
            });
            setFilter(false);
        }
    }

    function _handleFilter() {
        setFilter(true);
        setSelectedPayment(selectedPaymentStatus);
        setSelectedOrder(selectedOrderType);
        setSelectedStatus(selectedOrderStatus);
        setSelectedPaymentMethod(selectedPaymentType);
        setMilestone(selectedMilestone);
        setFrom(fromDate)
        setEnd(endDate);
        // setTableLoader(true);
    }


    function _handleClear() {
        setSelectedPaymentStatus(paymentStatusOptions[0]);
        setSelectedOrderType(ORDER_TYPES[0]);
        setSelectedOrderStatus(orderStatusOptions[0]);
        setSelectedPaymentType(PAYMENT_METHODS[0]);
        setSelectedMilestone(GOAL_TYPE[0]);
        setEndDate("DD-MM-YYYY");
        setFromDate("DD-MM-YYYY");
        setPageNumber(1);
    }

    const ORDER_COLUMNS = [
        {
            name: <div className={`w-fit-content d-flex gap-12px align-items-center`}>
                {/* Add your custom image or icon here */}
                <Icon icon={selectAll === true ? "checked" : "unchecked"}
                    size={18}
                    className="cursor-pointer"
                    color={selectAll === true && "#145da0"}
                    onClick={(event) => _handleSelectAll(event)} />

                <p className="mb-0">ORDER ID</p>
            </div>,
            selector: row => row.order_id,
            width: '170px',
            cell: row =>
                <div className="d-flex align-items-center gap-12px" onClick={() => _handleNavigate(row)}>

                    <Icon icon={selectedOrders.has(row['order_id']) ? "checked" : "unchecked"}
                        size={18}
                        color={selectedOrders.has(row['order_id']) && "#145da0"}
                        onClick={(event) => _handleRowCheckBox(row, event)} />


                    <p className="color-dark_charcoal e-montserrat-medium e-font-14 e-line-height-24 mb-0 mt-0">{row.order_id === null ? "-" : row.order_id}</p>
                </div>
        },
        {
            name: "ORDER NO.",
            cell: row => row.order_no === null ? "-" : row.order_no,
            width: "250px",
        },
        {
            name: "Customer name",
            cell: row => <LinkButton text={row.customer_name} handleClick={() => navigate('/customer/detail', {
                state: {
                    id: row.customer_id,
                    tab_index: 2
                }
            })} />,
            width: "250px",
        },
        {
            name: "Invested in ",
            cell: row => row.name,
            width: "250px"
        },
        {
            name: "Type",
            width: "110px",
            cell: row => row.type
        },
        {
            name: "Payment method",
            width: "160px",
            cell: row => row.payment_method
        },
        {
            name: "Ordered date",
            cell: row => row.order_time,
            width: "170px"
        },
        {
            name: "Amount",
            cell: row => row.amount,
            width: "120px"
        },
        {
            name: "NAV",
            cell: row => row.nav ? row.nav : "-",
            width: "120px"
        },
        {
            name: "Units allocated",
            cell: row => row.units_allocated_on ? row.units_allocated_on : "-",
            width: "160px"
        },
        {
            name: "Order status",
            width: "150px",
            cell: row => <Badge status={row.order_status}
                pendingClass="e-bg-golden-yellow"
                color={row.order_status === "Success" ? Config.BADGE_COLORS.green :
                    row.order_status === "Processing" ? Config.BADGE_COLORS.orange :
                        row.order_status === "Partially success" ? Config.BADGE_COLORS.orange :
                            Config.BADGE_COLORS.red}
                onClick={() => _handleNavigate(row)} />
        },
        {
            name: "Payment status",
            width: "150px",
            cell: row => row.payment_status === null || row.type === "Withdrawal" || row.type === "SWP" || row.type === "Switch" ? "-" : <div className="d-flex position-relative">
                <Badge status={row.payment_status}
                    color={row.payment_status === "Success" ? Config.BADGE_COLORS.green :
                        row.payment_status === "Processing" ? Config.BADGE_COLORS.orange :
                            Config.BADGE_COLORS.red} onClick={() => _handleNavigate(row)} />


                {
                    row.payment_status === "Failed" &&
                    <div id={`tooltip${row.id}`} onMouseEnter={(event) => _handleTooltip(event, row.order_id, row.failure_reason)}
                        onMouseLeave={() => _handleTooltip(null, null)} >
                        <Icon icon="circle-information" size="16px"
                            className="margin-2px-left cursor-pointer"
                        />

                    </div>
                }
            </div>
        },

    ]

    // API - get filter data
    const _getFilterData = () => {
        _getOrderFilterData().then((response) => {

            const order_status = response.data.order_status.map((item) => ({
                label: item.name,
                value: item.status
            }))

            const payment_status = response.data.payment_status.map((item) => ({
                label: item.name,
                value: item.status
            }))
            // setSelectedOrderType(response.data.order_types[0]);
            setPaymentStatusOptions(payment_status);
            // setSelectedPaymentStatus(response.data.payment_status[0]);
            setOrderStatusOptions(order_status);
            // setSelectedOrderStatus(response.data.order_status[0]);
            // setSelectedMilestone(response.data.milestone[0]);
            setFilterLoader(false)
        });
    }

    // API - get user list
    const _getOrderList = (search_value = "", payment_type = null, order_type = null, order_status = null, payment_method = null, is_goal_type = true, to_date = null, from_date = null, page = 1) => {
        setSearch(search_value);
        setSelectedPaymentStatus(payment_type);
        setSelectedOrderType(order_type);
        setSelectedOrderStatus(order_status);
        setSelectedPaymentType(payment_method);
        setSelectedMilestone(is_goal_type);
        setEndDate(to_date);
        setFromDate(from_date);
        setPageNumber(page);

        setSearchValue(search_value);
        setSelectedPayment(payment_type);
        setSelectedOrder(order_type);
        setSelectedStatus(order_status);
        setSelectedPaymentMethod(payment_method);
        setMilestone(is_goal_type);
        setEnd(to_date);
        setFrom(from_date);
        setPageNumber(page);

        setTableLoader(true);
        const url = "/order/list";
        const request = {
            payment_status: payment_type !== null ? payment_type.value : null,
            order_status: order_status !== null ? order_status.value : null,
            type: order_type !== null ? order_type.value : null,
            payment_method: payment_method !== null ? payment_method.value : null,
            from_date: from_date === "DD-MM-YYYY" ? null : from_date,
            to_date: to_date === "DD-MM-YYYY" ? null : to_date,
            is_goal: is_goal_type.value,
            page_num: page,
            page_size: 12,
            query: search_value.trim(),
        };

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setOrderList(response.data);

            } else {
                setOrderList([]);
            }
            setTableLoader(false);
            setFilter(false);
        })
    }

    // API - download list 
    const _downloadList = () => {
        setDownloadLoader(true);
        const url = "/order/list-download";
        const selected_ids = new Set(selectedOrders);
        const selected_array = Array.from(selected_ids);

        const request = {
            "order_ids": selected_array
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("Order list downloaded successfully", {
                    type: "success"
                });
                window.open(response.data.url, "_blank")
                setSelectedOrders(new Set());
                setSelectAll(false);
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setDownloadLoader(false)
        });
    }

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Order summary" type={1} />
            </div>
            {

                <div className="e-body-wrapper">
                    {/* <div className="row row-gap-16px">
                        <div className="col">
                            <InfoCard type={1}
                                title="Total orders"
                                data={summaryData.total_orders}
                                count={summaryData.total_orders_amount_text}
                                countText={summaryData.total_orders_amount} />
                        </div>
                        <div className="col">
                            <InfoCard type={1}
                                title="Completed orders"
                                data={summaryData.completed_orders}
                                count={summaryData.completed_orders_amount_text}
                                countText={summaryData.completed_orders_amount}
                            />
                        </div>
                        <div className="col">
                            <InfoCard type={1}
                                title="Processing orders"
                                data={summaryData.processing_orders}
                                count={summaryData.processing_orders_amount_text}
                                countText={summaryData.processing_orders_amount} />
                        </div>
                        <div className="col">
                            <InfoCard type={1}
                                title="Failed orders"
                                data={summaryData.failed_orders}
                                count={summaryData.failed_orders_amount_text}
                                countText={summaryData.failed_orders_amount} />
                        </div>
                        <div className="col">
                            <InfoCard type={1}
                                className="w-max-content"
                                title="Payment Pending orders"
                                data={summaryData.payment_pending_orders}
                                count={summaryData.payment_pending_orders_amount_text}
                                countText={summaryData.payment_pending_orders_amount} />
                        </div>
                    </div> */}
                    <div className="bg-white border-radius-24px padding-32px-tb mt-4">
                        <div className="row d-flex align-items-center mb-4">
                            <div className="col-lg-4 col-md-12 col-7">
                                <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-0 padding-32px-lr">
                                    Orders
                                </h4>
                            </div>
                            <div className="col-lg-3 col-md-5 col-5 text-end  pb-md-0 pb-3">
                                <DownloadButton
                                    onPress={_downloadList}
                                    loader={downloadLoader}
                                    disabled={selectedOrders.size === 0 ? true : false} />
                            </div>
                            <div className="col-lg-4 col-md-6 col-10 ps-md-0 ps-5">
                                {/* const _getOrderList = (search_value = "", payment_type = null, order_type = null, order_status = null, milestone = null, to_date = null, from_date = null, amount_from = null, amount_to = null, page = 1) => { */}

                                <SearchInput value={search}
                                    placeholder="Search with  order No."
                                    clear={() => {
                                        _getOrderList("", selectedPayment, selectedOrder, selectedStatus, selectedPaymentMethod, milestone, end, from, 1);
                                    }}
                                    valueChange={_handleSearch}
                                    handleSearch={(value) => _getOrderList(search, selectedPayment, selectedOrder, selectedStatus, selectedPaymentMethod, milestone, end, from, 1)} />
                            </div>
                            <div className="col-lg-1 col-md-1 col-2 ">
                                <Icon icon={filterEnabled ? "filter-active" : "filter-inactive"}
                                    size={42}
                                    className={`${filterLoader === true ? "disabled" : "cursor-pointer"}`}
                                    onClick={_handleToggleFilter} />
                            </div>
                        </div>

                        {filterEnabled && <div className="e-bg-light-gray py-3 padding-32px-lr margin-10px-bottom">
                            <div className="row">


                                <div className="col-lg-2 col-md-6">
                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">
                                        Payment status
                                    </label>
                                    <CustomSelect value={selectedPaymentStatus}
                                        options={paymentStatusOptions}
                                        selectChange={_handlePaymentStatus} />
                                </div>
                                <div className="col-lg-2 col-md-3">
                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">
                                        Order status
                                    </label>
                                    <CustomSelect value={selectedOrderStatus}
                                        options={orderStatusOptions}
                                        selectChange={_handleOrderStatus} />
                                </div>
                                <div className="col-lg-2 col-md-3">
                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">
                                        Order type
                                    </label>
                                    <CustomSelect value={selectedOrderType}
                                        options={ORDER_TYPES}
                                        selectChange={_handleOrderType} />
                                </div>
                                <div className="col-lg-2 col-md-3">
                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">
                                        Payment method
                                    </label>
                                    <CustomSelect value={selectedPaymentType}
                                        options={PAYMENT_METHODS}
                                        selectChange={_handlePaymentType} />
                                </div>
                                <div className="col-lg-2 col-md-3">
                                    <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">
                                        Investment type
                                    </label>
                                    <CustomSelect value={selectedMilestone}
                                        options={GOAL_TYPE}
                                        selectChange={_handleInvestmentType} />
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-lg-4 col-md-6">

                                    <CustomCalendar
                                        disabledDays={disabledDays}
                                        label="Ordered on"
                                        mode="range"
                                        start={fromDate}
                                        end={endDate}
                                        selectDate={_handleDate} />
                                </div>

                            </div>
                            {/* apply filter */}
                            <div className=" margin-32px-top d-flex gap-24px">

                                <PrimaryButton label="Filter now"
                                    className="padding-14px-tb padding-38px-lr"
                                    handleClick={_handleFilter} />


                                <SecondaryButton label="Clear"
                                    className="padding-14px-tb padding-38px-lr"
                                    handleClick={_handleClear} />

                            </div>
                        </div>}

                        <div className="position-relative">
                            <div className="d-flex justify-content-end e-montserrat-semi-bold e-font-12 e-line-height-14 align-items-center margin-12px-bottom  padding-32px-lr ">
                                <img src={require("../../Assets/Images/DataTable/scroll.gif")}
                                    alt="scroll"
                                    width={24}
                                    height={24}
                                    className="me-1 rotate-90deg" />
                                Scroll horizontally for view more  information
                            </div>
                            {
                                tableLoader === true ?
                                    <div className="h-80vh d-flex align-items-center justify-content-center">
                                        <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                                            alt={"loader"}
                                            width={60}
                                            height={60}
                                        />
                                    </div>
                                    :
                                    orderList.length === 0 ?
                                        <EmptyScreen
                                            title="No orders found"
                                            image={require("../../Assets/Images/Empty/no-data.png")}
                                            description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                        :
                                        <Fragment>
                                            <DataTableContainer data={orderList}
                                                columns={ORDER_COLUMNS}
                                                customStyles={TABLE_STYLE}
                                                selectableRows={false}
                                                paginationComponentOptions={{ noRowsPerPage: true }}
                                                pagination={true}
                                                perPage={12}
                                                // paginationTotalRows={orderList.total_count}
                                                // paginationDefaultPage={orderList.current_page}
                                                selectRows={(allSelected, selectedCount, selectedRows) => {
                                                }}
                                                rowClick={_handleNavigate}
                                            />
                                        </Fragment>
                            }

                        </div>
                        {showTooltip !== null && <div
                            className="e-order-list-tooltip position-absolute right-84px w-280px"
                            style={{ top: tooltipPosition.y + 10 }}
                        >
                            {tooltipContent}
                        </div>}
                    </div>
                </div>
            }
        </Fragment >
    )
}

export default OrdersListing;