/*
 *   File : list.js
 *   Author : https://evoqins.com
 *   Description : Risk profile list
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Custom components
import { CustomTitle } from "../../Components/Title";
import { PrimaryButton, TextButton } from "../../Components/Buttons";
import { ConfirmDeleteQuestionModal } from "../../Components/Modal";
import { EmptyScreen } from "../../Components/Others";

// Custom style
import APIService from "../../Services/api-service";


const RiskProfileList = () => {
    const navigate = useNavigate();

    const [deleteQuestion, setDeleteQuestion] = useState(null);
    const [questionList, setQuestionList] = useState([]);
    const [screenLoader, setScreenLoader] = useState(true);
    const [deleteLoader, setDeleteLoader] = useState(false);


    useEffect(() => {
        _getRiskQuestionnaireList();
    }, []);

    useEffect(() => {
        if (deleteQuestion !== null) {
            const modal = new Modal(document.getElementById("confirm-delete-question"));
            modal.show()
        }
    }, [deleteQuestion]);

    function _handleDeleteQuestion(detail) {
        setDeleteQuestion(detail)
    }

    // function _handleEditLog() {
    //     navigate("edit-log");
    // }

    function _handleNavigate() {
        navigate("manage-question",);
    }

    function _handleEdit(question, category) {
        navigate("manage-question", {
            state: {
                question: question,
                category: category
            }
        });
    }


    // API - get risk list data
    const _getRiskQuestionnaireList = () => {
        const url = "/quiz/list-questions";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setQuestionList(response.data);
            }
            else {
                setQuestionList([]);
            }
            setScreenLoader(false);
        });
    }

    // API - delete question
    const _deleteQuestion = async () => {
        setDeleteLoader(true);
        let status = false;
        const url = "/quiz/delete-question";
        const request = {
            question_id: deleteQuestion.question_id
        }
        await APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                status = true
                setDeleteQuestion(null);
                toast.dismiss();
                toast.success("Risk question deleted", {
                    type: "success",
                });
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
                status = false
            }
            setDeleteLoader(false);
        })
        return status
    }

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Manage Risk Profiling" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="bg-white border-radius-32px padding-40px-tb ">
                            <div className=" padding-32px-lr">
                                <div className="row d-flex align-items-center">
                                    <div className="col-lg-4 ">
                                        <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-dark-charcoal">
                                            Added questions
                                        </h4>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="d-flex justify-content-end align-items-center">
                                            <PrimaryButton label="Create new question"
                                                className="padding-10px-tb padding-12px-lr"
                                                handleClick={_handleNavigate} />
                                        </div>

                                    </div>
                                </div>


                            </div>
                            {
                                screenLoader === true ?
                                    <div className="h-80vh d-flex align-items-center justify-content-center">
                                        <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                                            alt={"loader"}
                                            width={60}
                                            height={60}
                                        />
                                    </div>
                                    :
                                    questionList.length === 0 ?
                                        <EmptyScreen className="my-5"
                                            title="No questions found"
                                            image={require("../../Assets/Images/Empty/no-data.png")}
                                            description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                        :
                                        <div className="pt-3 padding-32px-lr">
                                            {
                                                questionList.map((item) => {
                                                    return (
                                                        <div className="e-box-shadow-black36 p-3 border-radius-12px mb-3">
                                                            <h6 className="color-dark-charcoal e-font-14 e-montserrat-bold e-line-height-24 mb-0">
                                                                {item.name}
                                                            </h6>
                                                            <div className="e-bg-pastel-lavender p-3 border-radius-12px e-box-shadow-black36 mt-2">
                                                                {
                                                                    item.questions.map((question) => {
                                                                        return (
                                                                            <div className="d-flex justify-content-between mb-2">
                                                                                <div className="e-font-14 e-line-height-20 color-dark-charcoal e-montserrat-medium ">{question.question} <span className="e-montserrat-semi-bold">(weightage:{question.weight}%)</span> </div>
                                                                                <div className="d-flex gap-8px">
                                                                                    <TextButton name="Delete" hide_arrow={true}
                                                                                        className="color-deep-carmine-pink"
                                                                                        onPress={() => _handleDeleteQuestion(question)} />
                                                                                    <TextButton name="Edit" hide_arrow={true}
                                                                                        onPress={() => _handleEdit(question, item)} />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>

                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {
                deleteQuestion !== null && <ConfirmDeleteQuestionModal detail={deleteQuestion}
                    delete={_deleteQuestion}
                    confirm={_getRiskQuestionnaireList}
                    loader={deleteLoader}
                    closeModal={() => _handleDeleteQuestion(null)} />
            }

        </Fragment>
    )
}
export default RiskProfileList;