/*
 *   File : listing.js
 *   Author : https://evoqins.com
 *   Description : Customer list
 *   Version : 1.0.0
*/

// packages
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

// Custom components
import { CustomTitle } from "../../Components/Title";
import { DataTableContainer } from "../../Components/DataTable";
import { Icon } from '../../Components/Icon';
import { Badge } from "../../Components/Badge";
import { CustomSelect, SearchInput } from "../../Components/FormElements";
import { PrimaryButton, DownloadButton, SecondaryButton, LinkButton } from "../../Components/Buttons";
import { CustomCalendar, EmptyScreen } from "../../Components/Others";

// Custom styles
import Colors from '../../Styles/color.module.scss';

// Services
import APIService from "../../Services/api-service";
import Config from "../../Helper/config";
import { _forceNavigate, _formatDate } from "../../Helper/helper";
import { Modal } from "bootstrap";
import DeleteConfirmationModal from "../../Components/Modal/delete-confirmation";

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "24px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "24px",
            borderBottom: 'none !important',

            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },
            '&:hover': {
                backgroundColor: Colors.lavender_mist
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            userSelect: "none",
            cursor: "pointer"
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};


const BLOCK_OPTIONS = [
    {
        label: "All",
        value: null
    },
    {
        label: "Blocked",
        value: true
    },
    {
        label: "Active",
        value: false
    }
]

const INVESTED_OPTIONS = [
    {
        label: "All",
        value: null
    },
    {
        label: "Invested",
        value: true
    },
    {
        label: "Not Invested",
        value: false
    }
]


const CustomerListing = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const disabledDays = { after: new Date() };

    const [search, setSearch] = useState("");

    const [investmentStatus, setInvestmentStatus] = useState(INVESTED_OPTIONS[0]);
    const [blockedStatus, setBlockedStatus] = useState(BLOCK_OPTIONS[0]);

    const [startDate, setStartDate] = useState("DD-MM-YYYY");
    const [endDate, setEndDate] = useState("DD-MM-YYYY");

    // const [pageNumber, setPageNumber] = useState(1);

    const [customerData, setCustomerData] = useState([]);

    const [tableLoader, setTableLoader] = useState(true);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [filter, setFilter] = useState(false);

    const [selectAll, setSelectAll] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState(new Set());
    const [rowSelectFalse, setRowSelectFalse] = useState(false);
    const [userDetail, setUserDetail] = useState(null);

    _forceNavigate(() => {
        // eslint-disable-next-line
        if (location.pathname == "/customer") {
            navigate(-3)
        }
    });

    useEffect(() => {
        if (location.state !== null && Object.keys(location.state).length !== 0) {
            if (location.state.query) {
                setSearch(location.state.query)
            }
            _getUserList(location.state.investment_status, location.state.blocked_status, location.state.start_date, location.state.end_date, location.state.query,);
            // setPageNumber(location.state.page_num);
        }
    }, [location]);

    useEffect(() => {
        if (userDetail !== null) {
            const modal = new Modal(document.getElementById("delete-modal"));
            modal.show();
        }
    }, [userDetail]);

    useEffect(() => {
        if (filter === true) {
            _applyFilter();
        }
        // eslint-disable-next-line
    }, [filter]);


    useEffect(() => {
        if (location.state === null) {
            _getUserList(investmentStatus, blockedStatus, startDate, endDate, search);
        }
        // eslint-disable-next-line
    }, []);

    // for deselecting header checkbox in the table
    useEffect(() => {
        if (rowSelectFalse === true) {
            setSelectAll(false);
        }
    }, [rowSelectFalse])

    // for selecting all checkboxes
    useEffect(() => {
        if (selectAll === true && customerData.length !== 0) {
            const customer_id_set = new Set(customerData.map((item) => item.user_id));
            setSelectedUsers((prevSet) => new Set([...prevSet, ...customer_id_set]));
            setRowSelectFalse(false);
        }
        else {
            if (rowSelectFalse === false) {
                setSelectedUsers(new Set());
            }
        }
        // eslint-disable-next-line
    }, [selectAll, customerData]);


    // Navigate to detail
    function _handleNavigate(row) {
        navigate("/customer", {
            state: {
                id: row.user_id,
                history: "customer",
                start_date: startDate,
                end_date: endDate,
                investment_status: investmentStatus,
                blocked_status: blockedStatus,
                query: search
            }
        }, { replace: true });
        setTimeout(() => {
            navigate("detail", {
                state: {
                    id: row.user_id,
                    history: "customer",
                    start_date: startDate,
                    end_date: endDate,
                    investment_status: investmentStatus,
                    blocked_status: blockedStatus,
                    query: search
                }
            }, { replace: true });
        }, 0);
    }

    // Apply filter
    function _applyFilter() {
        _getUserList(investmentStatus, blockedStatus, startDate, endDate, search);
        setTableLoader(true);
        setFilterEnabled(false);
    }

    // Search 
    function _handleSearch(search_value) {
        setSearch(search_value);
        if (search_value === "") {
            _getUserList(investmentStatus, blockedStatus, startDate, endDate, search);
        }
    }

    // handle filter toggle
    function _handleToggleFilter() {
        setFilterEnabled(!filterEnabled)
    }

    // open block modal
    function _handleBlockUnblock(data) {
        setUserDetail(data)
    }

    // handle date range
    function _handleDate(date_range) {
        const formatted_start_date = _formatDate(date_range.from);
        const formatted_end_date = _formatDate(date_range.to);
        setStartDate(formatted_start_date);
        setEndDate(formatted_end_date);
    }

    // handle Invest status
    function _handleInvestmentStatus(select_value) {
        setInvestmentStatus(select_value);
    }

    // handle blocked status
    function _handleBlockedStatus(select_value) {
        setBlockedStatus(select_value);
    }

    // handle select row
    function _handleRowCheckBox(row, event) {
        // console.log("Row", row);
        event.stopPropagation();
        // console.log("before", selectedUsers)
        if (selectedUsers.has(row.user_id)) {
            setSelectedUsers((prevCustomerIds) => {
                const new_customer_ids = new Set(prevCustomerIds);
                new_customer_ids.delete(row.user_id);
                return new_customer_ids;
            });
        }
        else {
            setSelectedUsers((prevCustomerIds) => new Set([...prevCustomerIds, row.user_id]));
        }
        setRowSelectFalse(true);
    }

    // select all
    function _handleSelectAll(event) {
        event.stopPropagation();
        setSelectAll(!selectAll)
    }

    function _handleFilter() {
        setFilter(true);
        // setPageNumber(1);

    };

    // clear filter
    function _handleClear() {
        setBlockedStatus(BLOCK_OPTIONS[0]);
        setInvestmentStatus(INVESTED_OPTIONS[0]);
        setStartDate("DD-MM-YYYY");
        setEndDate("DD-MM-YYYY");
    }

    // User columns
    const USER_COLUMNS = [
        {
            name: <div className={`w-fit-content d-flex gap-12px align-items-center`}>
                {/* Add your custom image or icon here */}
                <Icon icon={selectAll === true ? "checked" : "unchecked"}
                    size={18}
                    className="cursor-pointer"
                    color={selectAll === true && "#145da0"}
                    onClick={(event) => _handleSelectAll(event)} />

                <p className="mb-0">FINZONE ID</p>
            </div>,
            selector: row => row.finvesto_customer_id,
            width: '300px',
            cell: row =>
                <div className="d-flex align-items-center gap-12px" onClick={() => _handleNavigate(row)}>

                    <Icon icon={selectedUsers.has(row['user_id']) ? "checked" : "unchecked"}
                        size={18}
                        color={selectedUsers.has(row['user_id']) && "#145da0"}
                        onClick={(event) => _handleRowCheckBox(row, event)} />


                    <p className="color-dark_charcoal e-montserrat-medium e-font-14 e-line-height-24 mb-0 mt-0">{row.finvesto_customer_id === null ? "-" : row.finvesto_customer_id}</p>
                </div>
        },
        {
            name: "Name",
            selector: row => row.name === null ? "-" : row.name,
            width: "160px",
            sortable: true,
        },
        {
            name: "Email",
            selector: row => row.email === null ? "-" : <a href={`mailto:${row.email}`} className="color-dark-charcoal" >{row.email}</a>,
            width: "160px",

        },
        {
            name: "Phone",
            selector: row => row.phone === null ? "-" : <a href={`mailto:${row.phone}`} className="color-dark-charcoal" >{row.phone}</a>,
            width: "160px"
        },
        {
            name: "Joined on",
            selector: row => row.joined === null ? "-" : row.joined,
            width: "160px"
        },

        {
            name: "KYC type ",
            selector: row => row.kyc_complaint === null ? "-" : row.kyc_complaint ? "KYC" : "Non KYC",
            width: "100px"
        },
        {
            name: "AUM",
            selector: row => row.aum === null ? "-" : row.aum,
            width: "150px"
        },
        {
            name: "Blocked Status",
            cell: row => row.signup_progress_status === null ? "-" : <Badge status={row.is_blocked ? "Blocked" : "Active"}
                color={row.is_blocked === false ? Config.BADGE_COLORS.green :
                    Config.BADGE_COLORS.magenta}
                onClick={() => _handleNavigate(row)} />,
            width: "180px"
        },
        {
            name: "Status",
            cell: row => row.signup_progress_status === null ? "-" : <Badge status={row.signup_progress_status}
                color={row.signup_progress_status === "LOGGED_IN" ? Config.BADGE_COLORS.blue :
                    row.signup_progress_status === "OTP_REQUESTED" ? Config.BADGE_COLORS.orange :
                        row.signup_progress_status === "INVESTED" ? Config.BADGE_COLORS.green :
                            Config.BADGE_COLORS.magenta}
                onClick={() => _handleNavigate(row)} />,
            width: "250px"
        },
        {
            name: '',
            cell: row => <LinkButton text={row.is_blocked ? "Unblock" : "Block"}
                handleClick={() => _handleBlockUnblock(row)} />
        }

    ]

    // API - get user list
    const _getUserList = (investment_status, blocked_status, start_date, end_date, query) => {
        setInvestmentStatus(investment_status);
        setBlockedStatus(blocked_status);
        setStartDate(start_date);
        setEndDate(end_date);
        setSearch(query)

        const url = "/user/list";
        const request = {
            "from_date": start_date === "DD-MM-YYYY" ? null : start_date,
            "to_date": end_date === "DD-MM-YYYY" ? null : end_date,
            "is_blocked": blocked_status === null ? null : blocked_status.value,
            "is_invested": investment_status === null ? null : investment_status.value,
            "query": query.trim(),
        };

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setCustomerData(response.data);
            } else {
                setCustomerData([]);
                setSelectAll(false);
            }
            setTableLoader(false);
            setFilter(false);
        })
    }

    // API - download list 
    const _downloadList = () => {
        setDownloadLoader(true);
        const url = "/user/list-download";
        const selected_ids = new Set(selectedUsers);
        const selected_array = Array.from(selected_ids);

        const request = {
            "user_ids": selected_array
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("User list downloaded successfully", {
                    type: "success"
                });
                window.open(response.data.url, "_blank")
                setSelectedUsers(new Set());
                setSelectAll(false);
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setDownloadLoader(false)
        });
    }


    // API - block unblock
    const _blockUnblockUser = async () => {
        setApiLoader(true);
        let status = false
        const url = "/user/block-unblock-user";
        const request = {
            "user_id": userDetail.user_id
        }
        await APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                const userList = [...customerData]
                const user_index = userList.findIndex((item) => item.user_id === userDetail.user_id);
                userList[user_index]["is_blocked"] = !userDetail.is_blocked
                setCustomerData(userList);
                status = true
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
                status = false;
            }

            setApiLoader(false);
        })
        return status
    }

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Customer summary"
                    type={1} />
            </div>
            {
                <div className="e-body-wrapper">
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="e-bg-white border-radius-32px pt-4 padding-40px-bottom">

                                {/* header section */}
                                <div className="px-4 mb-3">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-md-1 col-12">
                                            <h4 className='mb-0 e-montserrat-semi-bold e-font-16 e-line-height-24'>
                                                Customers
                                            </h4>
                                        </div>
                                        <div className="col-md-11 col-12">


                                            <div className="d-flex justify-content-end">
                                                {/* download customer reports */}
                                                <DownloadButton
                                                    disabled={selectedUsers.size !== 0 ? false : true}
                                                    className="my-lg-0 my-3"
                                                    loader={downloadLoader}
                                                    onPress={_downloadList} />

                                                {/* search users */}
                                                <div className="col-lg-5 px-md-3 ps-0 pe-3">
                                                    <SearchInput value={search}
                                                        placeholder="Search with name, PAN or ID"
                                                        clear={() => {
                                                            _getUserList(investmentStatus, blockedStatus, startDate, endDate, "");
                                                            setSearch("");
                                                        }}
                                                        valueChange={_handleSearch}
                                                        handleSearch={(value) => {
                                                            setTableLoader(true);
                                                            _getUserList(investmentStatus, blockedStatus, startDate, endDate, search)
                                                        }} />
                                                </div>
                                                {/* filter */}
                                                <Icon icon={filterEnabled ? "filter-active" : "filter-inactive"}
                                                    size={42}
                                                    className={"cursor-pointer"}
                                                    onClick={_handleToggleFilter} />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* Filter options */}
                                {
                                    filterEnabled === true &&
                                    <div className="e-bg-light-gray padding-12px-tb padding-32px-lr margin-10px-bottom">
                                        <div className="row">


                                            <div className="col-3">
                                                <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Join from - to</label>
                                                <CustomCalendar start={startDate}
                                                    end={endDate}
                                                    mode="range"
                                                    disabledDays={disabledDays}
                                                    selectDate={_handleDate} />
                                            </div>
                                            {/* risk profile filter */}
                                            <div className="col-2">
                                                <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Block status</label>
                                                <CustomSelect value={blockedStatus}
                                                    options={BLOCK_OPTIONS}
                                                    selectChange={_handleBlockedStatus} />
                                            </div>

                                            {/* investment status filter */}
                                            <div className="col-2">
                                                <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Investment status</label>
                                                <CustomSelect value={investmentStatus}
                                                    options={INVESTED_OPTIONS}
                                                    selectChange={_handleInvestmentStatus} />
                                            </div>
                                        </div>


                                        <div className="row margin-20px-top align-items-end">
                                            {/* apply filter */}
                                            <div className="col  d-flex gap-24px">

                                                <PrimaryButton label="Filter now"
                                                    className="padding-14px-tb padding-38px-lr"
                                                    handleClick={_handleFilter} />


                                                <SecondaryButton label="Clear"
                                                    className="padding-14px-tb padding-38px-lr"
                                                    handleClick={_handleClear} />

                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="position-relative">
                                    <div className="d-flex justify-content-end e-montserrat-semi-bold e-font-12 e-line-height-14 align-items-center margin-12px-bottom  padding-32px-lr ">
                                        <img src={require("../../Assets/Images/DataTable/scroll.gif")}
                                            alt="scroll"
                                            width={24}
                                            height={24}
                                            className="me-1 rotate-90deg" />
                                        Scroll horizontally for view more  information
                                    </div>
                                    {
                                        tableLoader === true ?
                                            <div className="h-80vh d-flex align-items-center justify-content-center">
                                                <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                                                    alt={"loader"}
                                                    width={60}
                                                    height={60}
                                                />
                                            </div>
                                            :
                                            customerData.length === 0 ?
                                                <EmptyScreen className="my-5"
                                                    title="No customers found"
                                                    image={require("../../Assets/Images/Empty/no-data.png")}
                                                    description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                                :
                                                <Fragment>
                                                    <DataTableContainer data={customerData}
                                                        columns={USER_COLUMNS}
                                                        customStyles={TABLE_STYLE}
                                                        selectableRows={false}
                                                        paginationComponentOptions={{ noRowsPerPage: true }}
                                                        pagination={true}
                                                        perPage={12}
                                                        selectRows={(allSelected, selectedCount, selectedRows) => {
                                                        }}
                                                        rowClick={_handleNavigate}
                                                    />
                                                    {/* <span className="e-montserrat-regular e-font-14 e-line-height-18 padding-24px-lr position-absolute color-jett-black bottom-10px">Showing {customerData.record} of {customerData.total_count} entries</span> */}
                                                </Fragment>
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            }
            {
                userDetail !== null &&
                <DeleteConfirmationModal title={userDetail.is_blocked === true ? "Unblock User" : "Block User"}
                    description={`Are you sure you want to ${userDetail.is_blocked === true ? "Unblock" : "Block"} this user?`}
                    loader={apiLoader}
                    label={userDetail.is_blocked === true ? "Unblock" : "Block"}
                    close={() => _handleBlockUnblock(null)}
                    confirm={_blockUnblockUser} />
            }

        </Fragment >
    )
}
export default CustomerListing;