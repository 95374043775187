/*
 *   File : radio-group.js
 *   Author URI : www.evoqins.com
 *   Description : Radio group component
 *   Integrations : NA
 *   Version : v1.1
 */
/* import packages */
import { useEffect, useState } from "react"

/* import components */
import Icon from "../Icon/icon";

const RadioGroup = (props) => {

    const [selectedValue, setSelectedValue] = useState(props.id);

    useEffect(() => {
        setSelectedValue(props.id)
    }, [props.id])

    const _handleClick = (item) => {
        setSelectedValue(item.id);
        props.radioHandler(item.id);
    }

    return (

        <div className={`${props.className} d-flex flex-wrap gap-8px`}>
            {
                props.data.map((item, key) => {

                    return (
                        <div key={key}
                            className={`d-flex gap-8px align-items-center cursor-pointer`}

                            onClick={() => _handleClick(item)}>
                            <Icon
                                icon={
                                    selectedValue === item.id ?
                                        'selected-radio' :
                                        'unselected-radio'
                                }
                                size={props.size ? props.size : 24} />
                            <span className={
                                selectedValue === item.id ?
                                    `color-rebecca-purple e-montserrat-medium e-font-14 e-line-height-24 letter-spacing-007em` :
                                    `color-dark-charcoal e-montserrat-medium e-font-14 e-line-height-24 letter-spacing-007em`}>
                                {item.label}
                            </span>
                        </div>
                    )

                })
            }
        </div>

    )
}

RadioGroup.defaultProps = {
    radioItemClassName: "",
    type: null,
    radioHandler: () => { }
}

export default RadioGroup;