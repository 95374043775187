/*
 *   File : user-settings.js
 *   Author URI : www.evoqins.com
 *   Description : Container page for user category settings
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

// Packages
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

// Components
import { CustomTitle } from "../../Components/Title";
import { PrimaryButton, TextButton } from "../../Components/Buttons";
import { DataTableContainer } from "../../Components/DataTable";
import { DeleteConfirmationModal, ManageCategory } from "../../Components/Modal";
import { EmptyScreen } from "../../Components/Others";

// Styles
import Colors from "../../Styles/color.module.scss";

// Services
import APIService from "../../Services/api-service";

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            cursor: "pointer",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const UserSettings = () => {
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [investorTypes, setInvestorTypes] = useState([]);
    const [pageLoader, setPageLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        _getInvestorTypes();
    }, []);

    useEffect(() => {
        if (openEditModal === true) {
            const modal = new Modal(document.getElementById("add-user-category-modal"), {});
            modal.show();
        }
    }, [openEditModal]);

    useEffect(() => {
        if (openDeleteModal === true) {
            const modal = new Modal(document.getElementById("delete-modal"), {});
            modal.show();
        }
    }, [openDeleteModal]);


    function _handleAdd() {
        setOpenEditModal(true);
    }

    const USER_COLUMNS = [
        {
            name: "Category name",
            width: "200px",
            cell: row => <div className="d-flex align-items-center gap-8px">
                <p className="e-montserrat-medium e-font-14 e-line-height-24px mb-0 color-dark-charcoal">{row.category}</p>
            </div>
        },
        {
            name: "Description",
            width: "360px",
            cell: row => <div className="d-flex align-items-center gap-8px">
                <p className="e-montserrat-medium e-font-14 e-line-height-24px mb-0 color-dark-charcoal">{row.description}</p>
            </div>
        },
        {
            name: "Action",
            // width: "100px",
            cell: row => <div className="d-flex gap-16px">
                <TextButton
                    className="e-font-14 e-line-height-24" name="Edit" hide_arrow={true}
                    onPress={() => _handleEdit(row)} />
                <TextButton
                    className="e-font-14 e-line-height-24"
                    name="Delete" hide_arrow={true}
                    onPress={() => _openDeleteModal(row.category_id)} />
            </div>
        }
    ]

    function _handleEdit(row) {
        setSelectedRow(row);
        setOpenEditModal(true);
    }

    function _handleDelete() {
        const category_types = [...investorTypes]
        const index = category_types.findIndex(item => item.category_id === categoryId);
        category_types.splice(index, 1)
        setInvestorTypes(category_types);
        setCategoryId(null);
    }

    function _handleClose() {
        setOpenEditModal(false);
        setOpenDeleteModal(false)
        setSelectedRow(null);
        setCategoryId(null);
    }

    function _openDeleteModal(id) {
        setOpenDeleteModal(true);
        setCategoryId(id);
    }

    // API -get investor types

    const _getInvestorTypes = () => {
        const url = "/quiz/get-category";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setInvestorTypes(response.data);
            } else {
                setInvestorTypes([]);
            }
            setPageLoader(false);
        })
    }


    // API - delete nudge
    const _deleteCategory = async () => {

        let status = false
        setApiLoader(true);

        const url = "/quiz/remove-category"
        const request = {
            category_id: categoryId
        }
        await APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                toast.success("Investor type deleted successfully", {
                    type: 'success'
                });
                status = true;
                _handleDelete();

            } else {
                toast.error(response.message, {
                    type: 'error'
                });
                status = false;
            }
            setApiLoader(false);
        })
        return status
    }


    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="App Settings" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="bg-white border-radius-32px padding-40px-tb ">
                    <div className="row padding-32px-lr">

                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-dark-charcoal mb-0">
                                    User categories
                                </h4>
                                <PrimaryButton label="Add new category"
                                    className="padding-10px-tb padding-12px-lr "
                                    handleClick={_handleAdd} />
                            </div>

                        </div>
                    </div>
                    {
                        pageLoader === true ?
                            <div className="h-80vh d-flex align-items-center justify-content-center">
                                <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                                    alt={"loader"}
                                    width={60}
                                    height={60}
                                />
                            </div>
                            :
                            investorTypes.length === 0 ?
                                <EmptyScreen className="my-5"
                                    title="No nudges found"
                                    image={require("../../Assets/Images/Empty/no-data.png")}
                                    description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                :
                                <DataTableContainer data={investorTypes}
                                    columns={USER_COLUMNS}
                                    customStyles={TABLE_STYLE} />
                    }
                </div>
            </div>

            {
                openEditModal === true &&
                <ManageCategory data={selectedRow}
                    close={_handleClose}
                    updateList={() => {
                        _handleClose();
                        _getInvestorTypes();
                    }} />
            }
            {
                openDeleteModal === true &&
                <DeleteConfirmationModal title="Delete user category"
                    description="Are you sure you want to delete the user category?"
                    confirm={_deleteCategory}
                    updateList={_handleDelete}
                    close={_handleClose}
                    loader={apiLoader} />

            }
        </Fragment>
    )
}

export default UserSettings;