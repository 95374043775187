/*
 *   File : dashboard.js
 *   Author : https://evoqins.com
 *   Description : Dashboard Container gives information about summary, aum, user, transaction of App  *   Version : 1.0.0
*/

// packages
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";

// Custom components
import { CustomTitle } from "../../Components/Title";
import { SummaryCard } from "../../Components/Cards";
import { AreaChart, CustomBarChart } from "../../Components/Chart";
import { CustomSelect } from "../../Components/FormElements";
import { EmptyScreen } from "../../Components/Others";

// Custom styles

// Services
import APIService from "../../Services/api-service";

// filter options
const CUMULATIVE_OPTIONS = [
    {
        label: "Daily", value: 1
    },
    {
        label: "Weekly", value: 2
    },
    {
        label: "Monthly", value: 3
    }
]
const Dashboard = () => {

    const [selectedFilter, setSelectedFilter] = useState(CUMULATIVE_OPTIONS[0]);
    const [chartData, setChartData] = useState({});
    const [dashboardSummary, setDashboardSummary] = useState({});
    const [cardLoader, setCardLoader] = useState(true);
    const [chartLoader, setChartLoader] = useState(true);

    useEffect(() => {
        _getDashboardData();
    }, []);

    useEffect(() => {
        _getChartData();
        //eslint-disable-next-line
    }, [selectedFilter.value]);

    function _handleSelect(value) {
        setSelectedFilter(value);
    }

    // API - get chart data
    const _getChartData = () => {
        const url = "/dashboard/cumulative-performance";
        const request = {
            filter_id: selectedFilter.value
        }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setChartData(response.data);
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setChartLoader(false);
        })
    }

    // API - get Dashboard data
    const _getDashboardData = () => {
        const url = "/dashboard/get";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setDashboardSummary(response.data);
            }
            setCardLoader(false);
        });
    }


    const Loading = () => {
        return (
            <div className="h-80vh d-flex align-items-center justify-content-center">
                <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                    alt={"loader"}
                    width={60}
                    height={60}
                />
            </div>
        )
    }


    return (


        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Dashboard" type={1} />
            </div>

            <div className="e-body-wrapper">

                {/* -------- Summary-------- */}
                {
                    cardLoader === true ?
                        <Loading />
                        :
                        Object.keys(dashboardSummary).length === 0 ?
                            <EmptyScreen className="my-5"
                                title="No data found"
                                image={require("../../Assets/Images/Empty/no-data.png")}
                                description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                            :
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../Assets/Images/Dashboard/aum.svg").default}
                                        title="Total aum"
                                        countText={dashboardSummary.aum}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../Assets/Images/Dashboard/total-users.svg").default}
                                        title="Total number of users"
                                        countText={dashboardSummary.total_users}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../Assets/Images/Dashboard/total-transactions.svg").default}
                                        title="Total transactions"
                                        countText={dashboardSummary.no_of_transactions}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../Assets/Images/Dashboard/pending-transactions.svg").default}
                                        title="Pending transactions"
                                        countText={dashboardSummary.pending_transactions}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../Assets/Images/Dashboard/invested-users.svg").default}
                                        title="Invested users"
                                        countText={dashboardSummary.active_investors}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../Assets/Images/Dashboard/non-invested-users.svg").default}
                                        title="Non-invested users"
                                        countText={dashboardSummary.inactive_investors}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../Assets/Images/Dashboard/average-aum.svg").default}
                                        title="Average AUM/ user"
                                        countText={dashboardSummary.average_aum}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mb-3">
                                    <SummaryCard icon={require("../../Assets/Images/Dashboard/invested-goals.svg").default}
                                        title="Created/ Invested goals"
                                        countText={dashboardSummary.invested_goals}
                                    />
                                </div>

                            </div>
                }

                {/* --------Cumulative performance-------- */}
                <div className="row mt-3">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <CustomTitle title="Cumulative performance" type={2} />

                        <CustomSelect
                            type={2}
                            value={selectedFilter}
                            options={CUMULATIVE_OPTIONS}
                            isSearchable={false}
                            selectChange={_handleSelect} />
                    </div>
                    {
                        chartLoader === true ?
                            <Loading />
                            :
                            Object.keys(chartData).length === 0 ?
                                <EmptyScreen className="my-5"
                                    title="No data found"
                                    image={require("../../Assets/Images/Empty/no-data.png")}
                                    description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                :
                                <Fragment>

                                    <div className="col-lg-6  margin-32px-bottom">
                                        <div className='e-bg-white e-box-shadow-black p-4 border-radius-16px'>
                                            <h4 className='mb-0 e-montserrat-semi-bold e-font-14 e-line-height-18 mb-3'>
                                                AUM
                                            </h4>
                                            <AreaChart
                                                data={chartData.aum.aum}
                                                labels={chartData.aum.label} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6  margin-32px-bottom">
                                        <div className='e-bg-white e-box-shadow-black p-4 border-radius-16px'>
                                            <h4 className='mb-0 e-montserrat-semi-bold e-font-14 e-line-height-18 mb-3'>
                                                New user registrations
                                            </h4>
                                            <CustomBarChart
                                                data={chartData.users.data}
                                                labels={chartData.users.label} />
                                        </div>
                                    </div>
                                </Fragment>
                    }
                </div>
            </div>
        </Fragment >
    )
}

export default Dashboard