
/*
 *   File : add-funds.js
 *   Author URI : https://evoqins.com
 *   Description : The modal component used for adding funds.
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, memo, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";


// Custom components
import { Icon } from "../Icon";
import { CustomTextInput1, SearchInput } from "../FormElements";
import { PrimaryButton, SecondaryButton } from "../Buttons";

// Custom styles
import style from "../../Styles/Components/add-fund.module.scss";

// Services
import APIService from "../../Services/api-service";

import SearchFund from "../../Assets/Images/Funds/search.png"


const AddFundsModal = (props) => {
    const answersRef = useRef(null);
    const [search, setSearch] = useState("");
    const [showList, setShowList] = useState(false);
    //eslint-disable-next-line
    const [fundList, setFundList] = useState([]);
    const [selectedFunds, setSelectedFunds] = useState([]);
    const [loader, setLoader] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);
    const [fundAdded, setFundAdded] = useState(false);
    const [assetClass, setAssetClass] = useState("");
    const [assetClassError, setAssetClassError] = useState("");

    useEffect(() => {
        var my_modal = document.getElementById("add-funds");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
            setShowList(false);
            setSelectedFunds([]);
        });
        //eslint-disable-next-line
    }, [props.closeModal]);

    useEffect(() => {
        setAssetClass(props.asset);
    }, [props.asset])

    useEffect(() => {
        if (props.data !== null) {
            setFundAdded(true);
            let selected_funds = []
            Object.entries(props.data).map(([asset, funds]) => (

                funds.map((fundObj) => {
                    return (
                        selected_funds.push({
                            alias: fundObj.fund_alias,
                            scheme_name: fundObj.name,
                            ...fundObj
                        })
                    )
                })
            ))
            setAssetClass(selected_funds[0].asset_class)
            setSelectedFunds(selected_funds);
        }
    }, [props.data])

    function _handleSearch(search_value) {
        setSearch(search_value);
        if (search_value === "") {
            _getFunds("");
        }
    }

    function _listFunds() {
        setShowList(true)
    }

    function _handleAllocation(input_value, index) {
        const selected = [...selectedFunds]
        selected[index]['allocation'] = input_value;
        selected[index]['error'] = ""
        setSelectedFunds(selected)
    }

    function _handleAlias(input_value, index) {
        const selected = [...selectedFunds]
        selected[index]['alias'] = input_value;
        selected[index]['error1'] = ""
        setSelectedFunds(selected)
    }
    function _handleAsset(input_value) {
        setAssetClass(input_value);
        setAssetClassError("");
    }

    function _handleFunds(data) {
        let funds = [...selectedFunds]
        const index = funds.findIndex((item) => item.scheme_code === data.scheme_code)
        if (index === -1) {
            funds.push({
                ...data,
                allocation: "",
                alias: "",
                error: "",
                error1: ""
            });

        } else {
            funds.splice(index, 1)
        }
        setSelectedFunds(funds)
    }

    function _handleAddFunds() {
        let valid = true;
        const selected_funds = [...selectedFunds]
        if (assetClass === "") {
            setAssetClassError("Asset class is required");
            answersRef.current.scrollIntoView({ block: 'start' });
            valid = false
        }
        for (let i = 0; i < selected_funds.length; i++) {
            if (selected_funds[i].allocation === "") {
                selected_funds[i].error = "Allocation is required";
                answersRef.current.scrollIntoView({ block: 'start' });
                valid = false
            }

            if (selected_funds[i].alias === "") {
                selected_funds[i].error1 = "Fund alias is required";
                answersRef.current.scrollIntoView({ block: 'start' });
                valid = false
            }
        }
        if (valid === true) {
            _addFund();
        }
        setSelectedFunds(selected_funds)
    }
    // search funds
    const _getFunds = (query) => {
        setLoader(true);
        const url = "/fund/search";
        const request = {
            "query": query,
            "page_num": 1
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setFundList(response.data);
                _listFunds();
            } else {
                setFundList([]);
            }
            setLoader(false);
        })
    }

    // add funds
    const _addFund = () => {
        setApiLoader(true);
        const funds = selectedFunds.map((item) => {
            return ({
                "allocation_id": item.id ? item.id : undefined,
                "asset_class": assetClass,
                "scheme_code": item.scheme_code,
                "allocation": item.allocation,
                "fund_alias": item.alias,
                "investment_style_id": props.type
            })
        })
        const url = props.data === null ? "/fund/add" : "/fund/edit-allocation";
        const request = {
            "funds": funds
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("Funds added successfully", {
                    type: "success"
                });
                props.updateList();
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        })
    }

    return (
        <Fragment>
            <div className={`modal fade ${style.e_add_fund}`}
                id="add-funds"
                tabIndex="-1"
                aria-labelledby="add-funds"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-32px-all ${style.e_content} `}>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                Add mutual fund
                            </h5>
                            <Icon icon="close"
                                size={18}
                                id="close-modal"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        <div className="">
                            <div className="row">

                                {
                                    fundAdded === true ?
                                        <Fragment>
                                            <CustomTextInput1 label="Asset class"
                                                placeHolder="Asset class"
                                                postfix="*"
                                                value={assetClass}
                                                error={assetClassError}
                                                isDisabled={props.asset ? true : false}
                                                handleChange={_handleAsset} />
                                            <div className={`${style.e_wrapper} pt-5`} ref={answersRef}>
                                                <div className="row pb-3">
                                                    <div className="col-6">
                                                        <h6 className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-dark-charcoal">Fund</h6>
                                                    </div>
                                                    <div className="col-3">
                                                        <h6 className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-dark-charcoal">Fund alias</h6>
                                                    </div>
                                                    <div className="col-3">
                                                        <h6 className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-dark-charcoal">Allocation(%)</h6>
                                                    </div>
                                                </div>
                                                {
                                                    selectedFunds.map((item, index) => {
                                                        return (
                                                            <div className="row pb-4 align-items-center">
                                                                <div className="col-6">
                                                                    <span className="e-montserrat-medium e-font-14 e-line-height-20 color-dark-charcoal">{item.scheme_name}</span>
                                                                </div>
                                                                <div className="col-3">
                                                                    <CustomTextInput1 value={item.alias}
                                                                        label="Fund Alias"
                                                                        placeHolder="Fund Alias"
                                                                        postfix="*"
                                                                        error={item.error1}
                                                                        handleChange={(input_value) => _handleAlias(input_value, index)} />
                                                                </div>
                                                                <div className="col-3">
                                                                    <CustomTextInput1 value={item.allocation}
                                                                        label="Allocation"
                                                                        placeHolder="Allocation"
                                                                        postfix="*"
                                                                        type="number"
                                                                        error={item.error}
                                                                        handleChange={(input_value) => _handleAllocation(input_value, index)} />
                                                                </div>

                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <div className="col-12 pb-3 padding-32px-top">
                                                <SearchInput className={`${style.e_search_input} py-2`}
                                                    value={search}
                                                    valueChange={_handleSearch}
                                                    clear={() => {
                                                        setSearch("");
                                                        _getFunds("");

                                                    }}
                                                    handleSearch={() => _getFunds(search === "" ? null : search)} />

                                            </div>
                                            {
                                                loader === true ?
                                                    <div className={`${style.e_wrapper} d-flex align-items-center`}>
                                                        <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                                                            alt={"loader"}
                                                            width={60}
                                                            height={60}
                                                            className="mx-auto" />
                                                    </div>
                                                    :
                                                    showList === true ?
                                                        fundList.length === 0 ?
                                                            <div className={`${style.e_wrapper} d-flex align-items-center`}>
                                                                <div className="col-12 text-center">
                                                                    <img src={SearchFund}
                                                                        alt="search"
                                                                        draggable={false}
                                                                        width={160}
                                                                        height={160}
                                                                        className="my-auto mx-auto" />
                                                                    <p className="mb-0 mt-3 color-silver-gray text-center e-font-14 e-line-height-18 e-montserrat-medium">Search for funds , No funds found</p>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className={`${style.e_wrapper}`}>
                                                                <div className="row">
                                                                    {

                                                                        fundList.map((item) => {
                                                                            return (

                                                                                <div className="col-sm-6 pb-4">
                                                                                    <div className="d-flex  align-items-start gap-8px cursor-pointer" onClick={() => _handleFunds(item)} >
                                                                                        <span>
                                                                                            <Icon icon={selectedFunds.some((data) => data.scheme_code === item.scheme_code) ? "checked" : "unchecked"} size={20}
                                                                                                className="cursor-pointer"
                                                                                            />
                                                                                        </span>
                                                                                        <div className="d-flex align-items-center">
                                                                                            <span className="e-montserrat-medium e-font-14 e-line-height-20 color-dark-charcoal">{item.scheme_name}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            )
                                                                        })}
                                                                </div>
                                                            </div>
                                                        :
                                                        <div className={`${style.e_wrapper} d-flex align-items-center`}>
                                                            <div className="col-12 text-center">
                                                                <img src={SearchFund}
                                                                    alt="search"
                                                                    draggable={false}
                                                                    width={160}
                                                                    height={160}
                                                                    className="my-auto mx-auto" />
                                                                <p className="mb-0 mt-3 color-silver-gray text-center e-font-14 e-line-height-18 e-montserrat-medium">Search for funds </p>
                                                            </div>
                                                        </div>
                                            }
                                        </Fragment>
                                }
                            </div>
                            <div className="d-flex justify-content-end pt-3">
                                <div className="d-flex gap-8px justify-content-end">
                                    {props.data === null && <SecondaryButton label={fundAdded === true ? "Back" : "Cancel"}
                                        cancel={fundAdded === false && "modal"}
                                        className="padding-14px-tb padding-50px-lr"
                                        handleClick={() => fundAdded === true && setFundAdded(false)} />}
                                    <PrimaryButton label={props.data === null ? "Add" : "Update"}
                                        className="padding-14px-tb padding-60px-lr"
                                        handleClick={() => fundAdded === true ? _handleAddFunds() : setFundAdded(true)}
                                        loader={fundAdded === true ? false : apiLoader}
                                        disabled={selectedFunds.length === 0 ? true : apiLoader} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment >
    )
}

export default memo(AddFundsModal);